import { FormattedMessage } from "react-intl";
import React from "react";

export const addressFields = [
  {
    label: <FormattedMessage id="addressLine1" />,
    key: "addressOne",
    value: "Street. Bedahan Kompl. Widia Residance Bedahan Sawangan",
  },
  {
    label: <FormattedMessage id="addressLine2" />,
    key: "addressTwo",
    value: "Street. Bedahan Kompl. Widia Residance Bedahan Sawangan",
  },
  {
    label: <FormattedMessage id="city" />,
    key: "city",
    value: "London",
  },
  {
    label: <FormattedMessage id="postCode" />,
    key: "postCode",
    value: "00100",
  },
  {
    label: <FormattedMessage id="countryRegion" />,
    key: "country",
    value: "England",
  },
  {
    label: <FormattedMessage id="houseType" />,
    key: "houseType",
    value: "Apartment",
  },
  {
    label: <FormattedMessage id="yourNeighbourhoodDistance" />,
    key: "yourNeighbourhoodDistance",
    value: "10",
  },
];

export const personalInfoFields = [
  {
    label: <FormattedMessage id="firstName" />,
    key: "firstName",
    value: "Mario",
    type: "text",
  },
  {
    label: <FormattedMessage id="lastName" />,
    key: "lastName",
    value: "Balotello",
    type: "text",
  },
  {
    label: <FormattedMessage id="dateOfBirth" />,
    key: "birthDay",
    value: "12/04/1989",
    type: "text",
  },
  {
    label: <FormattedMessage id="gender" />,
    key: "gender",
    value: "Male",
    type: "radio",
  },
  {
    label: <FormattedMessage id="phoneNumber" />,
    key: "phoneNumber",
    value: "+39388299822",
    type: "text",
  },
  {
    label: <FormattedMessage id="emergencyPhoneContact" />,
    key: "emergencyPhoneNumber",
    value: "+39388299822",
    type: "text",
  },
];

export const notificationsBlocks = [
  {
    key: "messages",
    title: <FormattedMessage id="messages" />,
    description: <FormattedMessage id="notifications.messages.description" />,
    contentFields: [
      {
        text: <FormattedMessage id="email" />,
        key: "email",
      },
      {
        text: <FormattedMessage id="textMessages" />,
        key: "textMessages",
      },
      {
        text: <FormattedMessage id="browserNotifications" />,
        key: "browserNotifications",
      },
    ],
  },
  {
    key: "reminders",
    title: <FormattedMessage id="reminders" />,
    description: <FormattedMessage id="notifications.reminders.description" />,
    contentFields: [
      {
        text: <FormattedMessage id="email" />,
        key: "email",
      },
      {
        text: <FormattedMessage id="textMessages" />,
        key: "textMessages",
      },
      {
        text: <FormattedMessage id="browserNotifications" />,
        key: "browserNotifications",
      },
    ],
  },
  {
    key: "promotions",
    title: <FormattedMessage id="promotions" />,
    description: <FormattedMessage id="notifications.promotions.description" />,
    contentFields: [
      {
        text: <FormattedMessage id="email" />,
        key: "email",
      },
      {
        text: <FormattedMessage id="textMessages" />,
        key: "textMessages",
      },
      {
        text: <FormattedMessage id="browserNotifications" />,
        key: "browserNotifications",
      },
    ],
  },
  {
    key: "support",
    title: <FormattedMessage id="support" />,
    description: <FormattedMessage id="notifications.support.description" />,
    contentFields: [
      {
        text: <FormattedMessage id="email" />,
        key: "email",
      },
      {
        text: <FormattedMessage id="textMessages" />,
        key: "textMessages",
      },
      {
        text: <FormattedMessage id="browserNotifications" />,
        key: "browserNotifications",
      },
    ],
  },
];

export const myPetBlock = [
  {
    key: "extra",
    contentFields: [
      {
        text: <FormattedMessage id="microchipped" />,
        key: "microchipped",
      },
      {
        text: <FormattedMessage id="houseTrained" />,
        key: "house_trained",
      },
      {
        text: <FormattedMessage id="playsWithOtherPets" />,
        key: "plays_with_other_pets",
      },
      {
        text: <FormattedMessage id="playsWithChildrenAndCats" />,
        key: "plays_with_children_and_cats",
      },
      {
        text: <FormattedMessage id="neutered" />,
        key: "neutered",
      },
    ],
  },
];
