export const fields: { [key: string]: any } = {
  extra: {
    microchipped: false,
    house_trained: false,
    plays_with_other_pets: false,
    play_with_children_and_cats: false,
    neutered: false,
  },
  gender: null,
};
