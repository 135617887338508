import React from "react";
import logoImg from "../../assets/images/logo.svg";
import * as routes from "../../shared/routes";
import { Link } from "react-router-dom";

export type LogoProps = {
  redirectTo?: string;
};

const Logo = ({ redirectTo = routes.ACCOUNT }: LogoProps) => {
  return (
    <Link to={redirectTo}>
      <img src={logoImg} alt="floofy" />
    </Link>
  );
};

export default Logo;
