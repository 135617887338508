import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

type MainType = {
  children: ReactNode;
};

const Main = ({ children }: MainType): JSX.Element => {
  return (
    <main className={classes.Main}>
      <div className={classes["Main-content"]}>{children}</div>
    </main>
  );
};

export default Main;
