import classes from "./styles.module.scss";
import documentsImage from "../../assets/images/documents.svg";
import { FormattedMessage } from "react-intl";
import React from "react";

const ProfileSentSuccessfully = (): JSX.Element => {
  return (
    <section className={classes.ProfileSentSuccessfully}>
      <img
        className={classes["ProfileSentSuccessfully-image"]}
        src={documentsImage}
        alt="success"
      />
      <h3 className={classes["ProfileSentSuccessfully-title"]}>
        <FormattedMessage id="profileSent.successfully.subtitle" />
      </h3>
      <p className={classes["ProfileSentSuccessfully-description"]}>
        <FormattedMessage
          id="profileSent.successfully.description"
          values={{
            strong: (
              <strong>
                <FormattedMessage id="profileSent.successfully.strong" />
              </strong>
            ),
          }}
        />
      </p>
    </section>
  );
};

export default ProfileSentSuccessfully;
