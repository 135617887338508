import React from "react";
import classes from "./styles.module.scss";
import Content from "../../containers/Content";
import { loginSecurity } from "../../shared/navigations/breadcrumbs";
import PasswordUpdate from "../../components/PasswordUpdate";
import Log from "../../components/Log";
import { deviceHistories } from "../../shared/__mocks__/log";
import { FormattedMessage } from "react-intl";
import Block from "../../components/Block";

const LoginAndSecurity = () => {
  return (
    <Content
      title={<FormattedMessage id="loginAndSecurity.title" />}
      breadcrumbsList={loginSecurity}
    >
      <div className={classes.LoginAndSecurity}>
        <Block
          title={<FormattedMessage id="login" />}
          description="Last updated 25 days ago"
        />
      </div>
      <section className={classes["LoginAndSecurity-content"]}>
        <PasswordUpdate onSave={(): void => console.log("Save!")} />
        <Log list={deviceHistories} />
      </section>
    </Content>
  );
};

export default LoginAndSecurity;
