import React from "react";
import classes from "./styles.module.scss";
import { Link, LinkProps } from "react-router-dom";
import arrowRightIcon from "../../assets/icons/arrow--right.svg";
import { useClassNames } from "../../shared/hooks";

export type BreadcrumbsProps = {
  list: LinkProps[];
  redirectEnabledForLastChild?: boolean;
};

const Breadcrumbs = ({
  list,
  redirectEnabledForLastChild = false,
}: BreadcrumbsProps) => {
  const classNames = useClassNames([
    classes.Breadcrumbs,
    !redirectEnabledForLastChild ? classes["Breadcrumbs--default"] : "",
  ]);

  return (
    <ul className={classNames}>
      {list.map((link, ix) => (
        <li className={classes["Breadcrumbs-item"]} key={ix}>
          <Link to={link.to}>{link.children}</Link>
          <img src={arrowRightIcon} alt="next" />
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
