export const fields: { [key: string]: any } = {
  messages: {
    email: false,
    textMessages: false,
    browserNotifications: false,
  },
  reminders: {
    email: false,
    textMessages: false,
    browserNotifications: false,
  },
  promotions: {
    email: false,
    textMessages: false,
    browserNotifications: false,
  },
  support: {
    email: false,
    textMessages: false,
    browserNotifications: false,
  },
};
