import Switch from "react-switch";
import classes from "./styles.module.scss";
import { ReactNode } from "react";
import { useClassNames } from "../../shared/hooks";

type SwitchRowProps = {
  text: string | ReactNode;
  checked: boolean;
  onChange(checked: boolean): void;
  hideBorder?: boolean;
};

const SwitchRow = ({
  text,
  checked,
  onChange,
  hideBorder = false,
}: SwitchRowProps) => {
  const classNames = useClassNames([
    classes.SwitchRow,
    hideBorder ? classes["SwitchRow--noBorder"] : "",
  ]);

  return (
    <article className={classNames}>
      <span className={classes["SwitchRow-text"]}>{text}</span>
      <div className={classes["SwitchRow-input"]}>
        <Switch
          checked={checked}
          onChange={onChange}
          onColor="#03AA65"
          onHandleColor="#FFF"
          offColor="#CED0D4"
          handleDiameter={21}
          uncheckedIcon={false}
          checkedIcon={false}
          height={25}
          width={50}
        />
      </div>
    </article>
  );
};

export default SwitchRow;
