import React from "react";
import classes from "./styles.module.scss";

const Loader = (): JSX.Element => {
  return (
    <div className={classes.Loader}>
      <div />
      <div />
    </div>
  );
};

export default Loader;
