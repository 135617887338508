import * as actionTypes from "./actionTypes";
import UsersService from "../../services/api/users";
import { resetSession } from "../../shared/helpers/session";
import { ErrorType, PetDataType, UserDataType } from "../../shared/types";

// GET
export const fetchUserStart = () => {
  return {
    type: actionTypes.FETCH_USER_START,
  };
};

export const fetchUserSuccess = (user: UserDataType) => {
  return {
    type: actionTypes.FETCH_USER_SUCCESS,
    user,
  };
};

export const fetchUserFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.FETCH_USER_FAIL,
    error,
  };
};

export const fetchUser = (id: string) => {
  return (dispatch: any) => {
    dispatch(fetchUserStart());

    UsersService.get(id).then(
      (response: { data: UserDataType }) => {
        dispatch(fetchUserSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(fetchUserFail(data.error));
      }
    );
  };
};

// UPDATE / PATCH
export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START,
  };
};

export const updateUserSuccess = (user: UserDataType) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    user,
  };
};

export const updateUserFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error,
  };
};

export const updateUser = (id: string, data: UserDataType) => {
  return (dispatch: any) => {
    dispatch(updateUserStart());

    UsersService.update(id, data).then(
      (response: { data: UserDataType }) => {
        dispatch(updateUserSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(updateUserFail(data.error));
      }
    );
  };
};

// GET
export const fetchUserPetsStart = () => {
  return {
    type: actionTypes.FETCH_USER_PETS_START,
  };
};

export const fetchUserPetsSuccess = (pets: PetDataType[]) => {
  return {
    type: actionTypes.FETCH_USER_PETS_SUCCESS,
    pets,
  };
};

export const fetchUserPetsFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.FETCH_USER_PETS_FAIL,
    error,
  };
};

export const fetchUserPets = (id: string) => {
  return (dispatch: any) => {
    dispatch(fetchUserPetsStart());

    UsersService.getPets(id).then(
      (response: { data: PetDataType[] }) => {
        dispatch(fetchUserPetsSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(fetchUserPetsFail(data.error));
      }
    );
  };
};

export const resetUser = () => {
  return {
    type: actionTypes.RESET_USER,
  };
};

export const resetUserPets = () => {
  return {
    type: actionTypes.RESET_USER_PETS,
  };
};
