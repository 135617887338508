import React, { ReactNode, useCallback } from "react";
import classes from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import * as routes from "../../shared/routes";
import { PetDataType } from "../../shared/types";
import * as actions from "../../store/actions";
import { connect } from "react-redux";

type PetTileType = {
  id?: string;
  imageSrc?: string;
  name: string;
  description?: string;
  // Includes age and weight
  extra?: string | ReactNode;
  pet?: PetDataType;
  waitingForFetching?: boolean;
  onGetPet: (id: string) => PetDataType;
};

const PetTile = ({
  id,
  name,
  description,
  extra,
  imageSrc,
  pet,
  waitingForFetching,
  onGetPet,
}: PetTileType): JSX.Element | null => {
  const history = useHistory();

  // TODO: Redux => get pet before redirecting
  const handlePetClick = useCallback(() => {
    if (id) {
      history.push(routes.MY_PETS_EDIT.replace(":id", id.toString()));
    }
  }, [history, id]);

  /*
  useEffect(() => {
    if (pet && id) {
      history.push(routes.MY_PETS_EDIT.replace(":id", id.toString()));
    }
  }, [pet, id, history]);*/

  if (!id) return null;

  return (
    <button onClick={handlePetClick} className={classes.PetTile}>
      <div className={classes["PetTile-background"]}>
        <div className={classes["PetTile-overlay"]} />
        {imageSrc && (
          <img className={classes["PetTile-image"]} alt={name} src={imageSrc} />
        )}
      </div>
      <div className={classes["PetTile-data"]}>
        <h3 className={classes["PetTile-name"]}>{name}</h3>
        <span className={classes["PetTile-description"]}>{description}</span>
        <p className={classes["PetTile-extra"]}>{extra}</p>
      </div>
    </button>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pet: state.petState.pet,
    waitingForFetching: state.petState.isFetching,
    petError: state.petState.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPet: (id: string) => dispatch(actions.fetchPet(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PetTile);
