import React, { Component, LazyExoticComponent } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { checkSession } from '../../shared/helpers/session';

interface privateRouteProps {
    // @ts-ignore
    component: typeof Component | typeof LazyExoticComponent;
    path: string;
    exact?: boolean;
    redirectTo?: string;
}

const privateRoute = ({
    component,
    exact,
    redirectTo,
    ...rest
}: privateRouteProps) => {
    if (!component) {
        throw Error('Component is undefined.');
    }

    const Component = component;

    const render = (props: RouteComponentProps<any>): React.ReactNode => {
        return checkSession() ? (
            <Component exact={exact} {...props} />
        ) : (
            <Redirect
                to={{
                    pathname: redirectTo,
                    state: {
                        from: props.location,
                    },
                }}
            />
        );
    };

    return <Route {...rest} render={render} />;
};

export default privateRoute;
