export enum WEIGHTS {
  regular = 400,
  medium = 500,
  bold = 700,
}

export enum COLOR {
  white = "white",
  black = "black",
  red = "red",
  green = "green",
  orange = "orange",
}

export enum BUTTON_TYPE {
  primary = "primary",
  secondary = "secondary",
}

export enum DEVICE_TYPE {
  smartphone = "smartphone",
  desktop = "desktop",
}

export enum INPUT_TYPE {
  text = "text",
  number = "number",
  password = "password",
}

export enum GENDER {
  male = "m",
  female = "f",
}

export enum ANSWER {
  yes = "yes",
  no = "no",
}

export enum FEATURE {
  experience = "experience",
  skill = "skill",
  pet = "pet",
  size = "size",
  time = "time",
  age = "age",
  requirement = "requirement",
}

export enum FLOW {
  row = "row",
  column = "column",
}
