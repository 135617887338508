import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { LinkType } from "../../shared/types";

type LinksListType = {
  title: string | ReactNode;
  items: LinkType[];
};

const LinksList = ({ title, items = [] }: LinksListType): JSX.Element => {
  return (
    <section className={classes.LinksList}>
      <h4 className={classes["LinksList-title"]}>{title}</h4>
      <ul className={classes["LinksList-items"]}>
        {items.map((item, ix) => (
          <li key={ix}>
            <a href={item.link}>{item.label}</a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default LinksList;
