import React, { useCallback, useMemo } from "react";
import classes from "./styles.module.scss";
import Logo from "../../components/Logo";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import GreenButton from "../../components/GreenButton";
import { BUTTON_TYPE } from "../../shared/enums";
import { useHistory } from "react-router-dom";
import * as routes from "../../shared/routes";
import { SessionType } from "../../shared/types";
import { checkSession } from "../../shared/helpers/session";

type HeaderProps = {
  onLogout: () => void;
  onResetPet: () => void;
  onResetUser: () => void;
  me: SessionType;
};

const Header = ({
  onLogout,
  me,
  onResetPet,
  onResetUser,
}: HeaderProps): JSX.Element => {
  const history = useHistory();

  const handleLogout = useCallback(() => {
    onResetPet();
    onResetUser();
    onLogout();
    history.push(routes.SIGNIN);
  }, [onLogout, history, onResetPet, onResetUser]);

  const showSignoutButton = useMemo(() => {
    return Boolean(me || checkSession());
  }, [me]);

  return (
    <header className={classes.Header}>
      <div className={classes["Header-content"]}>
        <Logo />
        {showSignoutButton && (
          <GreenButton
            type={BUTTON_TYPE.secondary}
            text={<FormattedMessage id="general.signout" />}
            onClick={handleLogout}
          />
        )}
      </div>
    </header>
  );
};

const mapStateToProps = (state: any) => {
  return {
    me: state.sessionState.session,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => dispatch(actions.resetUserSession()),
    onResetPet: () => dispatch(actions.resetPet()),
    onResetUser: () => dispatch(actions.resetUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
