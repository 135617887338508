import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/helpers/utilities";
import { PetDataType } from "../../shared/types";

type ActionType = {
  type: string;
  pet: PetDataType;
  error: string;
};

const INITIAL_STATE = {
  pet: null,
  isCreating: false,
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  actionSuccess: false,
  error: null,
};

const fetchPetStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    pet: null,
    isFetching: true,
    didInvalidate: false,
    error: null,
    actionSuccess: false,
  });
};

const fetchPetSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    pet: action.pet,
    isFetching: false,
  });
};

const fetchPetFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true,
  });
};

const createPetStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    pet: null,
    isCreating: true,
    didInvalidate: false,
    error: null,
    actionSuccess: false,
  });
};

const createPetSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    pet: action.pet,
    isCreating: false,
    actionSuccess: true,
  });
};

const createPetFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isCreating: false,
    didInvalidate: true,
  });
};

const updatePetStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    isUpdating: true,
    didInvalidate: false,
    error: null,
    actionSuccess: false,
  });
};

const updatePetSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    pet: action.pet,
    isUpdating: false,
    didInvalidate: false,
    error: null,
    actionSuccess: true,
  });
};

const updatePetFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isUpdating: false,
    didInvalidate: true,
  });
};

const resetPet = (state = INITIAL_STATE) => {
  return updateObject(state, {
    pet: null,
    isCreating: false,
    isFetching: false,
    isUpdating: false,
    didInvalidate: false,
    actionSuccess: false,
    error: null,
  });
};

const petReducer = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    // FETCH
    case actionTypes.FETCH_PET_START:
      return fetchPetStart(state);
    case actionTypes.FETCH_PET_SUCCESS:
      return fetchPetSuccess(state, action);
    case actionTypes.FETCH_PET_FAIL:
      return fetchPetFail(state, action);

    // CREATE
    case actionTypes.CREATE_PET_START:
      return createPetStart(state);
    case actionTypes.CREATE_PET_SUCCESS:
      return createPetSuccess(state, action);
    case actionTypes.CREATE_PET_FAIL:
      return createPetFail(state, action);

    // UPDATE
    case actionTypes.UPDATE_PET_START:
      return updatePetStart(state);
    case actionTypes.UPDATE_PET_SUCCESS:
      return updatePetSuccess(state, action);
    case actionTypes.UPDATE_PET_FAIL:
      return updatePetFail(state, action);

    // RESET
    case actionTypes.RESET_PET:
      return resetPet(state);

    default:
      return state;
  }
};

export default petReducer;
