import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { LinkProps } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import { useClassNames } from "../../shared/hooks";

type ContentProps = {
  breadcrumbsList?: LinkProps[];
  redirectEnabledForLastChild?: boolean;
  title?: string | ReactNode;
  children: ReactNode;
  noMargins?: boolean;
};

const Content = ({
  breadcrumbsList,
  title,
  children,
  noMargins = false,
  redirectEnabledForLastChild = false,
}: ContentProps) => {
  const classNames = useClassNames([
    classes.Content,
    noMargins ? classes["Content--noMargins"] : "",
  ]);

  return (
    <section className={classNames}>
      {breadcrumbsList && (
        <div className={classes["Content-breadcrumbs"]}>
          <Breadcrumbs
            list={breadcrumbsList}
            redirectEnabledForLastChild={redirectEnabledForLastChild}
          />
        </div>
      )}
      {title && <h1 className={classes["Content-title"]}>{title}</h1>}
      <div className={classes["Content-main"]}>{children}</div>
    </section>
  );
};

export default Content;
