import * as actionTypes from "./actionTypes";
import { resetSession } from "../../shared/helpers/session";
import { ErrorType, PetDataFieldsType, PetDataType } from "../../shared/types";
import PetsService from "../../services/api/pets";

// FETCH
export const fetchPetStart = () => {
  return {
    type: actionTypes.FETCH_PET_START,
  };
};

export const fetchPetSuccess = (pet: PetDataType) => {
  return {
    type: actionTypes.FETCH_PET_SUCCESS,
    pet,
  };
};

export const fetchPetFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.FETCH_PET_FAIL,
    error,
  };
};

export const fetchPet = (id: string) => {
  return (dispatch: any) => {
    dispatch(fetchPetStart());

    PetsService.get(id).then(
      (response: { data: PetDataType }) => {
        dispatch(fetchPetSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(fetchPetFail(data.error));
      }
    );
  };
};

// CREATE
export const createPetStart = () => {
  return {
    type: actionTypes.CREATE_PET_START,
  };
};

export const createPetSuccess = (pet: PetDataType) => {
  return {
    type: actionTypes.CREATE_PET_SUCCESS,
    pet,
  };
};

export const createPetFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.CREATE_PET_FAIL,
    error,
  };
};

export const createPet = (data: PetDataType) => {
  return (dispatch: any) => {
    dispatch(createPetStart());

    PetsService.create(data).then(
      (response: { data: PetDataType }) => {
        dispatch(createPetSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(createPetFail(data.error));
      }
    );
  };
};

// UPDATE / PATCH
export const updatePetStart = () => {
  return {
    type: actionTypes.CREATE_PET_START,
  };
};

export const updatePetSuccess = (user: PetDataType) => {
  return {
    type: actionTypes.CREATE_PET_SUCCESS,
    user,
  };
};

export const updatePetFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.CREATE_PET_FAIL,
    error,
  };
};

export const updatePet = (id: string, data: PetDataFieldsType) => {
  return (dispatch: any) => {
    dispatch(updatePetStart());

    PetsService.update(id, data).then(
      (response: { data: PetDataType }) => {
        dispatch(updatePetSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(updatePetFail(data.error));
      }
    );
  };
};

export const resetPet = () => {
  return {
    type: actionTypes.RESET_PET,
  };
};
