import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./styles.module.scss";
import ThreeColumnsLayout from "../../containers/ThreeColumnsLayout";
import NavigationWizard from "../../components/NavigationWizard";
import { FormattedMessage } from "react-intl";
import { WIZARD_ITEMS } from "../../shared/navigations/sitterSignup";
import WizardProgress from "../../components/WizardProgress";
import AboutYou from "../../containers/AboutYou";
import YourHome from "../../containers/YourHome";
import * as Scroll from "react-scroll";
import ExperienceAndSkills from "../../containers/ExperienceAndSkills";
import ServicesAndRates from "../../containers/ServicesAndRates";
import BookingPolicy from "../../containers/BookingPolicy";
import ServicesAvailability from "../../containers/ServicesAvailability";
import PayoutMethod from "../../containers/PayoutMethod";
import Modal from "../../containers/Modal";
import { useHistory } from "react-router-dom";
import * as routes from "../../shared/routes";
import ProfileSentSuccessfully from "../../components/ProfileSentSuccessfully";

const scroll = Scroll.animateScroll;

const SitterSignup = () => {
  const [currentNavWizardId, setCurrentNavWizardId] = useState(1);
  const [wizardStatus, setWizardStatus] = useState(WIZARD_ITEMS);
  const [isReadyForSubmitting, setIsReadyForSubmitting] = useState(false);
  const [profileSentSuccessfully, setProfileSentSuccessfully] = useState(false);
  const history = useHistory();

  const continueHandler = useCallback(() => {
    history.push(routes.ACCOUNT);
  }, [history]);

  const showProfileSentSuccessfullyModal = useCallback(() => {
    setProfileSentSuccessfully(true);
  }, []);

  // eslint-disable-next-line
  const onCompleteStep = useCallback(
    (id: number) => {
      const newWizardStatus = wizardStatus.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            isCompleted: true,
          };
        }

        return item;
      });

      setWizardStatus(newWizardStatus);
    },
    [wizardStatus]
  );

  const onHandleNavWizardClick = useCallback(
    (id: number) => setCurrentNavWizardId(id),
    []
  );

  const onNextClick = useCallback(() => {
    if (currentNavWizardId < wizardStatus.length) {
      setCurrentNavWizardId(currentNavWizardId + 1);
    }
    onCompleteStep(currentNavWizardId);
  }, [currentNavWizardId, onCompleteStep, wizardStatus]);

  const onPrevClick = useCallback(() => {
    if (currentNavWizardId > 1) {
      setCurrentNavWizardId(currentNavWizardId - 1);
    }
  }, [currentNavWizardId]);

  const valueForWizardProgress = useMemo(() => {
    const currentItem = wizardStatus.find(
      (item) => item.id === currentNavWizardId
    );

    return (currentItem && currentItem.weight) || 0;
  }, [wizardStatus, currentNavWizardId]);

  useEffect(() => {
    const status = wizardStatus.filter((item) => !item.isCompleted).length;
    setIsReadyForSubmitting(!status);
  }, [wizardStatus]);

  const renderContent = useMemo((): JSX.Element | null => {
    if (currentNavWizardId === 1)
      return <AboutYou onNext={(): void => onNextClick()} />;
    if (currentNavWizardId === 2)
      return (
        <YourHome
          onNext={(): void => onNextClick()}
          onPrev={(): void => onPrevClick()}
        />
      );
    if (currentNavWizardId === 3)
      return (
        <ExperienceAndSkills
          onNext={(): void => onNextClick()}
          onPrev={(): void => onPrevClick()}
        />
      );
    if (currentNavWizardId === 4)
      return (
        <ServicesAndRates
          onNext={(): void => onNextClick()}
          onPrev={(): void => onPrevClick()}
        />
      );
    if (currentNavWizardId === 5)
      return (
        <BookingPolicy
          onNext={(): void => onNextClick()}
          onPrev={(): void => onPrevClick()}
        />
      );
    if (currentNavWizardId === 6)
      return (
        <ServicesAvailability
          onNext={(): void => onNextClick()}
          onPrev={(): void => onPrevClick()}
        />
      );
    if (currentNavWizardId === 7)
      return (
        <PayoutMethod
          onNext={(): void => onNextClick()}
          onPrev={(): void => onPrevClick()}
        />
      );

    return null;
  }, [currentNavWizardId, onNextClick, onPrevClick]);

  useEffect(() => {
    scroll.scrollToTop();
  }, [currentNavWizardId]);

  return (
    <div className={classes.SitterSignup}>
      <ThreeColumnsLayout
        firstColumn={
          <NavigationWizard
            title={
              <FormattedMessage id="sitterSignup.wizardNavigation.title" />
            }
            currentId={currentNavWizardId}
            items={wizardStatus}
            onClick={onHandleNavWizardClick}
          />
        }
        secondColumn={renderContent}
        thirdColumn={
          <WizardProgress
            value={isReadyForSubmitting ? 100 : valueForWizardProgress}
            text={
              <FormattedMessage
                id="progress.done.text"
                values={{
                  value: (
                    <strong>
                      {isReadyForSubmitting ? 100 : valueForWizardProgress}
                    </strong>
                  ),
                }}
              />
            }
            onClick={showProfileSentSuccessfullyModal}
          />
        }
      />
      {profileSentSuccessfully && (
        <Modal
          confirmFullWidth={true}
          title={<FormattedMessage id="profileSent.successfully.title" />}
          onConfirm={continueHandler}
          confirmTextId="continue"
        >
          <ProfileSentSuccessfully />
        </Modal>
      )}
    </div>
  );
};

export default SitterSignup;
