import { FormattedMessage } from "react-intl";
import { NavigationWizardItem } from "../types";

export const WIZARD_ITEMS: NavigationWizardItem[] = [
  {
    id: 1,
    text: <FormattedMessage id="sitterSignup.wizardNavigation.aboutYou" />,
    isCompleted: false,
    weight: 0,
  },
  {
    id: 2,
    text: <FormattedMessage id="sitterSignup.wizardNavigation.yourHome" />,
    isCompleted: false,
    weight: 10,
  },
  {
    id: 3,
    text: (
      <FormattedMessage id="sitterSignup.wizardNavigation.experienceAndSkills" />
    ),
    isCompleted: false,
    weight: 25,
  },
  {
    id: 4,
    text: (
      <FormattedMessage id="sitterSignup.wizardNavigation.servicesAndRates" />
    ),
    isCompleted: false,
    weight: 40,
  },
  {
    id: 5,
    text: <FormattedMessage id="sitterSignup.wizardNavigation.bookingPolicy" />,
    isCompleted: false,
    weight: 60,
  },
  {
    id: 6,
    text: (
      <FormattedMessage id="sitterSignup.wizardNavigation.servicesAvailability" />
    ),
    isCompleted: false,
    weight: 75,
  },
  {
    id: 7,
    text: <FormattedMessage id="sitterSignup.wizardNavigation.payoutMethod" />,
    isCompleted: false,
    weight: 95,
  },
];
