import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./styles.module.scss";
import Content from "../../containers/Content";
import { myPets } from "../../shared/navigations/breadcrumbs";
import { FormattedMessage } from "react-intl";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as routes from "../../shared/routes";
import { fakePets } from "../../shared/__mocks__/pets";
import {
  FieldType,
  FileDataType,
  PetDataFieldsType,
  PetDataType,
} from "../../shared/types";
import PhotoUploader from "../../components/PhotoUploader";
import dogIcon from "../../assets/icons/dog.svg";
import Input from "../../components/Input";
import { GENDER, INPUT_TYPE } from "../../shared/enums";
import { myPetBlock } from "../../shared/__mocks__/fields";
import SwitchRow from "../../components/SwitchRow";
import { fields } from "../../shared/data/myPet";
import imagePlaceholder from "../../assets/icons/image-placeholder.svg";
import Textarea from "../../components/Textarea";
import RadioGroup from "../../components/RadioGroup";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import OutlineButton from "../../components/OutlineButton";
import GreenButton from "../../components/GreenButton";
import { getCurrentUserId, resetSession } from "../../shared/helpers/session";

type MatchParams = {
  id: string;
};

type MyPetProps = {
  onCreatePet: (data: PetDataType) => PetDataType;
  onUpdatePet: (id: string, data: PetDataFieldsType) => PetDataType;
  onGetPet: (id: string) => PetDataType;
  pet: PetDataType;
  waitingForCreating: boolean;
  waitingForFetching: boolean;
  waitingForUpdating: boolean;
  petError: string;
  actionSuccess: boolean;
  onResetPet: () => void;
};

let FIELDS: FieldType = {
  name: {
    value: "",
  },
  weight: {
    value: "",
  },
  careInstructions: {
    value: "",
  },
  veterinaryInformation: {
    value: "",
  },
};

const MyPet = ({
  pet,
  onCreatePet,
  onUpdatePet,
  onGetPet,
  waitingForFetching,
  waitingForCreating,
  waitingForUpdating,
  petError,
  actionSuccess,
  onResetPet,
}: MyPetProps) => {
  const [currentFields, setCurrentFields] = useState(FIELDS);
  const [tempValues, setTempValues] = useState(fields);
  const [currentPetId, setCurrentPetId] = useState<string | undefined>();

  const match = useRouteMatch<MatchParams>();
  const history = useHistory();

  useEffect(() => {
    if (match && match.params) {
      const { id } = match.params;

      if (id !== "new") {
        onGetPet(id);
      }
    } else {
      history.push(routes.MY_PETS);
    }
  }, [match, history, onGetPet]);

  const onCancel = useCallback(() => {
    history.push(routes.ACCOUNT);
  }, [history]);

  const onSave = useCallback(() => {
    const debugData = {
      ...currentFields,
      ...tempValues,
    };

    const userId = getCurrentUserId();

    if (!userId) {
      resetSession();
      history.push(routes.SIGNIN);
    } else {
      let flags: string[] = [];

      Object.keys(debugData?.extra).forEach((key) => {
        if (debugData?.extra[key]) {
          flags.push(key);
        }
      });

      const data: PetDataType = {
        userId,
        name: debugData.name.value,
        weight: +debugData.weight.value,
        gender: debugData.gender,
        type: "dog",
        microchip: "xyz123xyz",
        flags,
        careInstruction: debugData.careInstructions.value,
        veterinaryInformation: debugData.veterinaryInformation.value,
        files: [],
      };

      if (currentPetId) {
        onUpdatePet(currentPetId, data);
      } else {
        onCreatePet(data);
      }
    }
  }, [
    currentFields,
    tempValues,
    history,
    onCreatePet,
    currentPetId,
    onUpdatePet,
  ]);

  const breadcrumbs = useMemo(() => {
    if (currentPetId && pet && !waitingForFetching) {
      return [...myPets].concat([
        {
          to: routes.EMPTY,
          children: <span>{pet.name}</span>,
        },
      ]);
    }

    return [...myPets];
  }, [currentPetId, pet, waitingForFetching]);

  const onHandleChangeTextValue = useCallback(
    (name: string, value: string) => {
      const newFields = {
        ...currentFields,
        [name]: {
          ...currentFields[name],
          value,
        },
      };

      setCurrentFields(newFields);

      return true;
    },
    [currentFields]
  );

  const onHandleCheck = useCallback(
    (value: boolean, section: string, property: string) => {
      const newTempValues = {
        ...tempValues,
        [section]: {
          ...tempValues[section],
          [property]: value,
        },
      };

      setTempValues(newTempValues);
    },
    [tempValues]
  );

  const setGender = useCallback(
    (gender: GENDER) => {
      const newTempValues = {
        ...tempValues,
        gender,
      };

      setTempValues(newTempValues);
    },
    [tempValues]
  );

  useEffect(() => {
    if (pet) {
      setCurrentPetId(pet.id);

      const newCurrentFields = {
        name: { value: pet.name },
        weight: { value: pet.weight },
        careInstructions: { value: pet.careInstruction },
        veterinaryInformation: { value: pet.veterinaryInformation },
      };

      setTempValues({
        gender: pet.gender,
        extra: {
          microchipped: pet.flags?.includes("microchipped"),
          house_trained: pet.flags?.includes("house_trained"),
          plays_with_other_pets: pet.flags?.includes("plays_with_other_pets"),
          play_with_children_and_cats: pet.flags?.includes(
            "play_with_children_and_cats"
          ),
          neutered: pet.flags?.includes("neutered"),
        },
      });

      // @ts-ignore
      setCurrentFields(newCurrentFields);
    }
  }, [pet]);

  useEffect(() => {
    if (actionSuccess) {
      history.push(routes.MY_PETS);
    }
  }, [history, actionSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      onResetPet();
    };
  }, [onResetPet]);

  const saveDisabled = useMemo(
    () => waitingForUpdating || waitingForCreating || waitingForFetching,
    [waitingForUpdating, waitingForCreating, waitingForFetching]
  );

  const handleUploadImage = useCallback(
    (files: FileDataType[]) => {
      const userId = getCurrentUserId();

      if (userId && files?.length) {
        const list: string[] = files
          .map((file) => file?.id || "")
          .filter((item) => item);
        onUpdatePet(userId, { files: list });
      }
    },
    [onUpdatePet]
  );

  return (
    <Content
      title={<FormattedMessage id="myPet.title" />}
      redirectEnabledForLastChild={!Boolean(currentPetId)}
      breadcrumbsList={breadcrumbs}
    >
      <div className={classes.MyPet}>
        <PhotoUploader
          onCallbackFile={handleUploadImage}
          tag="pet-image"
          title={<FormattedMessage id="addMainPhoto.pet" />}
          src={null}
          alternativeText={fakePets[0].name}
          uploaderIconUrl={dogIcon}
          contentText={<FormattedMessage id="uploadPetPhoto" />}
        />
        <div className={classes["MyPet-fields"]}>
          <Input
            label={<FormattedMessage id="label.petName" />}
            value={currentFields.name.value}
            onChange={(value: string): boolean =>
              onHandleChangeTextValue("name", value)
            }
          />
          <RadioGroup
            title={<FormattedMessage id="gender" />}
            items={[
              {
                label: <FormattedMessage id="male" />,
                onChange: () => setGender(GENDER.male),
                id: "male",
                checked: tempValues.gender === GENDER.male,
              },
              {
                label: <FormattedMessage id="female" />,
                onChange: () => setGender(GENDER.female),
                id: "female",
                checked: tempValues.gender === GENDER.female,
              },
            ]}
          />
          <Input
            label={<FormattedMessage id="label.weight" />}
            value={currentFields.weight.value}
            type={INPUT_TYPE.number}
            onChange={(value: string): boolean =>
              onHandleChangeTextValue("weight", value)
            }
          />
          <section className={classes["MyPet-switches"]}>
            {myPetBlock.map((block, ix) => {
              return (
                block.contentFields &&
                block.contentFields.length > 0 &&
                block.contentFields.map((field, i) => (
                  <SwitchRow
                    hideBorder={true}
                    key={i}
                    text={field.text}
                    checked={tempValues[block.key][field.key]}
                    onChange={(checked: boolean): void =>
                      onHandleCheck(checked, block.key, field.key)
                    }
                  />
                ))
              );
            })}
          </section>
          <Textarea
            label={<FormattedMessage id="label.careInstructions" />}
            value={currentFields.careInstructions.value}
            onChange={(value: string): boolean =>
              onHandleChangeTextValue("careInstructions", value)
            }
            suggestionText={
              <FormattedMessage id="careInstructions.suggestion" />
            }
          />
          <Textarea
            label={<FormattedMessage id="label.veterinaryInformation" />}
            value={currentFields.veterinaryInformation.value}
            onChange={(value: string): boolean =>
              onHandleChangeTextValue("veterinaryInformation", value)
            }
            suggestionText={
              <FormattedMessage id="veterinaryInformation.suggestion" />
            }
          />
          <PhotoUploader
            title={<FormattedMessage id="photoGallery" />}
            alternativeText="pet-images"
            uploaderIconUrl={imagePlaceholder}
            contentText={<FormattedMessage id="uploadPetPictures" />}
            suggestion={<FormattedMessage id="uploadGallery.multi" />}
            fullWidth={true}
            multiple={true}
            onCallbackFile={handleUploadImage}
            tag="pet-gallery"
          />
        </div>
        <footer className={classes["MyPet-footer"]}>
          <OutlineButton
            text={<FormattedMessage id="cancel" />}
            onClick={onCancel}
          />
          <GreenButton
            disabled={saveDisabled}
            text={<FormattedMessage id="save" />}
            onClick={onSave}
          />
        </footer>
      </div>
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pet: state.petState.pet,
    waitingForCreating: state.petState.isCreating,
    waitingForFetching: state.petState.isFetching,
    waitingForUpdating: state.petState.isUpdating,
    petError: state.petState.error,
    actionSuccess: state.petState.actionSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreatePet: (data: PetDataType) => dispatch(actions.createPet(data)),
    onUpdatePet: (id: string, data: PetDataFieldsType) =>
      dispatch(actions.updatePet(id, data)),
    onGetPet: (id: string) => dispatch(actions.fetchPet(id)),
    onResetPet: () => dispatch(actions.resetPet()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPet);
