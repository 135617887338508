import React, { ReactNode, useMemo } from "react";
import classes from "./styles.module.scss";
import OutlineButton from "../../components/OutlineButton";
import { FormattedMessage } from "react-intl";
import GreenButton from "../../components/GreenButton";

type InnerContentProps = {
  overallText: ReactNode | string;
  title?: string | ReactNode;
  description?: string | ReactNode;
  children: ReactNode;
  onHandleBack?(): void;
  onHandleNext?(): void;
};

const InnerContent = ({
  title,
  description,
  overallText,
  children,
  onHandleBack,
  onHandleNext,
}: InnerContentProps) => {
  const showFooter = useMemo(() => Boolean(onHandleNext || onHandleBack), [
    onHandleBack,
    onHandleNext,
  ]);

  return (
    <section className={classes.InnerContent}>
      {overallText && (
        <span className={classes["InnerContent-overallText"]}>
          {overallText}
        </span>
      )}
      {title && <h1 className={classes["InnerContent-title"]}>{title}</h1>}
      {description && (
        <h1 className={classes["InnerContent-description"]}>{description}</h1>
      )}
      <div className={classes["InnerContent-main"]}>{children}</div>
      {showFooter && (
        <footer className={classes["InnerContent-footer"]}>
          {onHandleBack && (
            <OutlineButton
              onClick={onHandleBack}
              text={<FormattedMessage id="back" />}
            />
          )}
          {onHandleNext && (
            <GreenButton
              onClick={onHandleNext}
              text={<FormattedMessage id="next" />}
            />
          )}
        </footer>
      )}
    </section>
  );
};

export default InnerContent;
