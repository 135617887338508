import { CertificateType } from "../types";

export const fakeCertificates: CertificateType[] = [
  {
    id: 1,
    name: "Certificate A",
    url:
      "https://cdn.vox-cdn.com/thumbor/utr18XdUj3eYM1VkptkbMVTp9wI=/1400x1050/filters:format(png)/cdn.vox-cdn.com/uploads/chorus_asset/file/22660397/windows11lede.png",
  },
  {
    id: 2,
    name: "Certificate B",
    url:
      "https://cdn.vox-cdn.com/thumbor/utr18XdUj3eYM1VkptkbMVTp9wI=/1400x1050/filters:format(png)/cdn.vox-cdn.com/uploads/chorus_asset/file/22660397/windows11lede.png",
  },
  {
    id: 3,
    name: "Certificate C",
    url:
      "https://cdn.vox-cdn.com/thumbor/utr18XdUj3eYM1VkptkbMVTp9wI=/1400x1050/filters:format(png)/cdn.vox-cdn.com/uploads/chorus_asset/file/22660397/windows11lede.png",
  },
];
