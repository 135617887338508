import { SelectOption } from "../types";

export const houseTypes: SelectOption[] = [
  {
    value: "",
    label: "Select an house type",
  },
  {
    value: "apartment",
    label: "Apartment",
  },
  {
    value: "farm",
    label: "Farm",
  },
  {
    value: "house",
    label: "House",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const countries: SelectOption[] = [
  {
    value: "",
    label: "Select a country",
  },
  {
    value: "uk",
    label: "United Kingdom",
  },
  {
    value: "it",
    label: "Italy",
  },
  {
    value: "es",
    label: "Espana",
  },
  {
    value: "fr",
    label: "France",
  },
  {
    value: "de",
    label: "Germany",
  },
];

export const minimumNotices: SelectOption[] = [
  {
    value: "",
    label: "Select a minimum notice",
  },
  {
    value: "0-d",
    label: "Same day bookings",
  },
  {
    value: "1-d",
    label: "I need at least 1 day notice for bookings",
  },
  {
    value: "2-d",
    label: "I need at least 2 days notice for bookings",
  },
  {
    value: "1-w",
    label: "I need at least 1 week notice for bookings",
  },
];

export const cancellationPolicies: SelectOption[] = [
  {
    value: "",
    label: "Select a cancellation policy",
  },
  {
    value: "flexible",
    label: "Flexible: Full refund with 4 day notice. 50% refund after",
  },
  {
    value: "moderate",
    label: "Moderate: Full refund with 7 day notice. 50% refund after",
  },
  {
    value: "strict",
    label: "Strict: no refund",
  },
];
