import React, { useCallback, useState } from "react";
import classes from "./styles.module.scss";
import { DeviceHistoryType } from "../../shared/types";
import GreenButton from "../GreenButton";
import { BUTTON_TYPE, DEVICE_TYPE } from "../../shared/enums";
import dayjs from "dayjs";
import smartphoneImage from "../../assets/images/smartphone.svg";
import desktopImage from "../../assets/images/desktop.svg";
import { FormattedMessage } from "react-intl";

export type LogProps = {
  list: DeviceHistoryType[];
};

const Log = ({ list = [] }: LogProps): JSX.Element | null => {
  const [tempList, setTempList] = useState(list);

  const onHandleRemove = useCallback(
    (id: number) => {
      const newTempList = tempList.filter((item) => item.id !== id);

      setTempList(newTempList);
    },
    [tempList]
  );

  if (!tempList.length) return null;

  return (
    <section className={classes.Log}>
      <h3 className={classes["Log-title"]}>
        <FormattedMessage id="deviceHistory" />
      </h3>
      <ul className={classes["Log-list"]}>
        {tempList.map((item) => (
          <li className={classes["Log-item"]} key={item.id}>
            <div className={classes["Log-icon"]}>
              {item.type === DEVICE_TYPE.smartphone && (
                <img src={smartphoneImage} alt="smartphone" />
              )}
              {item.type === DEVICE_TYPE.desktop && (
                <img src={desktopImage} alt="smartphone" />
              )}
            </div>
            <div className={classes["Log-info"]}>
              <span>
                {item.os} · {item.browser}
              </span>
              <span>
                {item.location} ·{" "}
                {`${dayjs(item.createdAt).format("MMMM DD, YYYY")} at ${dayjs(
                  item.createdAt
                ).format("hh:mm a")}`}
              </span>
            </div>
            <GreenButton
              onClick={(): void => onHandleRemove(item.id)}
              type={BUTTON_TYPE.secondary}
              text={<FormattedMessage id="logoutDevice" />}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Log;
