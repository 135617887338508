import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import PhotoUploader from "../../components/PhotoUploader";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";
import userPlaceholderIcon from "../../assets/icons/user-placeholder.svg";
import Input from "../../components/Input";
import Group from "../../components/Group";
import RadioGroup from "../../components/RadioGroup";
import { ANSWER, GENDER } from "../../shared/enums";
import imagePlaceholder from "../../assets/icons/image-placeholder.svg";
import Textarea from "../../components/Textarea";
import GreenButton from "../../components/GreenButton";
import CheckboxGroup from "../../components/CheckboxGroup";

let FIELDS: {
  [key: string]: {
    value: string;
    selected?: string[];
  };
} = {
  firstName: {
    value: "",
  },
  lastName: {
    value: "",
  },
  day: {
    value: "",
  },
  month: {
    value: "",
  },
  year: {
    value: "",
  },
  gender: {
    value: "",
  },
  phoneInput: {
    value: "",
  },
  aboutMe: {
    value: "",
  },
  activities: {
    value: "",
  },
  haveChildren: {
    value: "",
  },
  headline: {
    value: "",
  },
  ageCategory: {
    value: "",
    selected: [],
  },
};

type AboutYouProps = {
  onNext(): void;
};

const AboutYou = ({ onNext }: AboutYouProps) => {
  const [currentFields, setCurrentFields] = useState(FIELDS);

  const onHandleChangeValue = useCallback(
    (name: string, value: string) => {
      const newFields: {
        [key: string]: {
          value: string;
          selected?: string[];
        };
      } = {
        ...currentFields,
        [name]: {
          ...currentFields[name],
          value,
        },
      };

      if (name === "haveChildren" && newFields.ageCategory.selected) {
        newFields.ageCategory.selected = [];
      }

      setCurrentFields(newFields);

      return true;
    },
    [currentFields]
  );

  const onSelectAgeCategory = useCallback(
    (cat: string) => {
      let tempFields = { ...currentFields };
      if (tempFields.ageCategory.selected) {
        const found = tempFields.ageCategory.selected.find(
          (item) => item === cat
        );

        if (found) {
          tempFields.ageCategory.selected = tempFields.ageCategory.selected.filter(
            (item) => item !== cat
          );
        } else {
          tempFields.ageCategory.selected.push(cat);
        }

        setCurrentFields(tempFields);
      }
    },
    [currentFields]
  );

  useEffect(() => {
    console.log("Data", currentFields);
  }, [currentFields]);

  return (
    <InnerContent
      overallText={<FormattedMessage id="aboutYou.overallText" />}
      title={<FormattedMessage id="aboutYou.title" />}
    >
      <div className={classes.AboutYou}>
        <div className={classes["AboutYou-photo"]}>
          <PhotoUploader
            title={<FormattedMessage id="label.profilePhoto" />}
            contentText={<FormattedMessage id="aboutYou.upload.photo" />}
            suggestion={<FormattedMessage id="aboutYou.suggestion.picture" />}
            uploaderIconUrl={userPlaceholderIcon}
          />
        </div>
        <div className={classes["AboutYou-content"]}>
          <Group
            numberOfColumns={2}
            suggestion={<FormattedMessage id="aboutYou.name.suggestion" />}
          >
            <Input
              label={<FormattedMessage id="label.firstName" />}
              value={currentFields.firstName.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("firstName", value)
              }
            />
            <Input
              label={<FormattedMessage id="label.lastName" />}
              value={currentFields.lastName.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("lastName", value)
              }
            />
          </Group>
          <Group
            numberOfColumns={3}
            suggestion={<FormattedMessage id="aboutMe.age.suggestion" />}
          >
            <Input
              label={<FormattedMessage id="label.day" />}
              value={currentFields.day.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("day", value)
              }
            />
            <Input
              label={<FormattedMessage id="label.month" />}
              value={currentFields.month.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("month", value)
              }
            />
            <Input
              label={<FormattedMessage id="label.year" />}
              value={currentFields.year.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("year", value)
              }
            />
          </Group>
          <RadioGroup
            title={<FormattedMessage id="gender" />}
            items={[
              {
                label: <FormattedMessage id="male" />,
                onChange: () => onHandleChangeValue("gender", GENDER.male),
                id: "male",
                checked: currentFields.gender.value === GENDER.male,
              },
              {
                label: <FormattedMessage id="female" />,
                onChange: () => onHandleChangeValue("gender", GENDER.female),
                id: "female",
                checked: currentFields.gender.value === GENDER.female,
              },
            ]}
          />
          <Group
            suggestion={
              <FormattedMessage id="aboutMe.phoneNumber.suggestion" />
            }
          >
            <Input
              label={<FormattedMessage id="label.phoneInput" />}
              value={currentFields.phoneInput.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("phoneInput", value)
              }
            />
          </Group>
          <RadioGroup
            title={<FormattedMessage id="haveChildren" />}
            description={<FormattedMessage id="haveChildren.description" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () => onHandleChangeValue("haveChildren", ANSWER.yes),
                id: "yes",
                checked: currentFields.haveChildren.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("haveChildren", ANSWER.no),
                id: "no",
                checked: currentFields.haveChildren.value === ANSWER.no,
              },
            ]}
          />
          {currentFields.haveChildren.value === ANSWER.yes && (
            <CheckboxGroup
              description={<FormattedMessage id="ageCategory" />}
              items={[
                {
                  label: <FormattedMessage id="infant" />,
                  onChange: () => onSelectAgeCategory("0-1"),
                  id: "0-1",
                  checked: Boolean(
                    currentFields.ageCategory.selected &&
                      currentFields.ageCategory.selected.includes("0-1")
                  ),
                },
                {
                  label: <FormattedMessage id="toddler" />,
                  onChange: () => onSelectAgeCategory("2-4"),
                  id: "2-4",
                  checked: Boolean(
                    currentFields.ageCategory.selected &&
                      currentFields.ageCategory.selected.includes("2-4")
                  ),
                },
                {
                  label: <FormattedMessage id="child" />,
                  onChange: () => onSelectAgeCategory("5-12"),
                  id: "5-12",
                  checked: Boolean(
                    currentFields.ageCategory.selected &&
                      currentFields.ageCategory.selected.includes("5-12")
                  ),
                },
                {
                  label: <FormattedMessage id="teen" />,
                  onChange: () => onSelectAgeCategory("13-19"),
                  id: "13-19",
                  checked: Boolean(
                    currentFields.ageCategory.selected &&
                      currentFields.ageCategory.selected.includes("13-19")
                  ),
                },
              ]}
            />
          )}
          <Group
            suggestion={<FormattedMessage id="aboutMe.headline.suggestion" />}
          >
            <Input
              label={<FormattedMessage id="label.headline" />}
              value={currentFields.headline.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("headline", value)
              }
            />
          </Group>
          <Textarea
            label={<FormattedMessage id="label.aboutMe" />}
            value={currentFields.aboutMe.value}
            onChange={(value: string): boolean =>
              onHandleChangeValue("aboutMe", value)
            }
            suggestionText={<FormattedMessage id="aboutMe.suggestion" />}
          />
          <Textarea
            label={<FormattedMessage id="label.activities" />}
            value={currentFields.activities.value}
            onChange={(value: string): boolean =>
              onHandleChangeValue("activities", value)
            }
            suggestionText={<FormattedMessage id="activities.suggestion" />}
          />
          <PhotoUploader
            title={<FormattedMessage id="photoGallery" />}
            alternativeText="user-images"
            uploaderIconUrl={imagePlaceholder}
            contentText={<FormattedMessage id="uploadPictures" />}
            suggestion={<FormattedMessage id="uploadPictures.suggestion" />}
            fullWidth={true}
          />
        </div>
        <footer className={classes["AboutYou-footer"]}>
          <GreenButton
            onClick={(): void => onNext()}
            text={<FormattedMessage id="next" />}
          />
        </footer>
      </div>
    </InnerContent>
  );
};

export default AboutYou;
