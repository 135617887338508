import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import addIcon from "../../assets/icons/add.svg";

type EmptyBoxType = {
  iconUrl?: string;
  text: string | ReactNode;
  onClick(): void;
  minHeight?: string;
};

const EmptyBox = ({
  onClick,
  iconUrl = addIcon,
  text,
  minHeight,
}: EmptyBoxType): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={classes.EmptyBox}
      style={{ minHeight: minHeight || "100%" }}
    >
      <img className={classes["EmptyBox-image"]} src={iconUrl} alt="add" />
      <span className={classes["EmptyBox-text"]}>{text}</span>
    </button>
  );
};

export default EmptyBox;
