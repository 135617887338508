import classes from "./styles.module.scss";
import React, { ReactNode, useMemo } from "react";
import InputRange, { Range } from "react-input-range";
import { useClassNames } from "../../shared/hooks";
import "react-input-range/lib/css/index.css";
import "./override.scss";

type RangeSliderProps = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  min?: number;
  max: number;
  step?: number;
  value?: number;
  onChange(value: number | Range): void;
  format?: string;
};

const RangeSlider = ({
  min = 1,
  max,
  step = 1,
  title,
  description,
  value,
  onChange,
  format = "",
}: RangeSliderProps): JSX.Element | null => {
  const currentValue = useMemo(() => value || min, [value, min]);

  const titleClassNames = useClassNames([
    classes["RangeSlider-title"],
    Boolean(description) ? classes["RangeSlider-title--withDescription"] : "",
  ]);

  return (
    <div className={classes.RangeSlider}>
      {title && <h3 className={titleClassNames}>{title}</h3>}
      {description && (
        <p className={classes["RangeSlider-description"]}>{description}</p>
      )}
      <div className={classes["RangeSlider-content"]}>
        <InputRange
          maxValue={max}
          minValue={min}
          value={currentValue}
          onChange={onChange}
          step={step}
          formatLabel={(value) => `${value}${format ? ` ${format}` : ""}`}
        />
      </div>
    </div>
  );
};

export default RangeSlider;
