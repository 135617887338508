import constants from "../constants";
import { SessionType } from "../types";

export const checkSession = (): boolean => {
  return localStorage.getItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN) !== null;
};

export const getCurrentUserId = (): string | null => {
  return localStorage.getItem(constants.LOCAL_STORAGE.KEYS.USER_ID) || null;
};

export const resetSession = (): void => {
  localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN);
  localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
  localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_ROLE);
};

export const setSession = (session: SessionType): void => {
  localStorage.setItem(constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN, session?.token);
  localStorage.setItem(
    constants.LOCAL_STORAGE.KEYS.USER_ID,
    session?.uid?.toString()
  );
  if (session?.roles?.length) {
    localStorage.setItem(
      constants.LOCAL_STORAGE.KEYS.USER_ROLE,
      session.roles[0]
    );
  }
};
