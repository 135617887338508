import { DeviceHistoryType } from "../types";
import { DEVICE_TYPE } from "../enums";

export const deviceHistories: DeviceHistoryType[] = [
  {
    id: 1,
    location: "Palembang, South Sumatr",
    type: DEVICE_TYPE.smartphone,
    os: "OS X 10.14",
    browser: "Firefox",
    createdAt: "2021-05-26T15:54:49.000Z",
  },
  {
    id: 2,
    location: "Palermo, Italy",
    type: DEVICE_TYPE.desktop,
    os: "Windows 10.1",
    browser: "Google Chrome",
    createdAt: "2021-05-28T09:54:49.000Z",
  },
];
