import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";
import GreenButton from "../../components/GreenButton";
import OutlineButton from "../../components/OutlineButton";
import CheckboxGroup from "../../components/CheckboxGroup";
import RangeSlider from "../../components/RangeSlider";
import { Range } from "react-input-range";
import { FEATURE } from "../../shared/enums";
import { fakeCertificates } from "../../shared/__mocks__/certificates";
import CertificatesList from "../../components/CertificatesList";

const MAX_YEARS = 40;

let FIELDS: {
  [key: string]: {
    value: string;
    selected?: string[];
  };
} = {
  experience: {
    value: "",
    selected: [],
  },
  years: {
    value: "10",
  },
  skills: {
    value: "",
    selected: [],
  },
};

type ExperienceAndSkillsProps = {
  onNext(): void;
  onPrev(): void;
};

const ExperienceAndSkills = ({ onNext, onPrev }: ExperienceAndSkillsProps) => {
  const [currentFields, setCurrentFields] = useState(FIELDS);

  const onCheckOption = useCallback(
    (exp: string, type: FEATURE) => {
      let tempFields = { ...currentFields };

      if (type === FEATURE.experience) {
        if (tempFields.experience.selected) {
          const found = tempFields.experience.selected.find(
            (item) => item === exp
          );

          if (found) {
            tempFields.experience.selected = tempFields.experience.selected.filter(
              (item) => item !== exp
            );
          } else {
            tempFields.experience.selected.push(exp);
          }

          setCurrentFields(tempFields);
        }
      }

      if (type === FEATURE.skill) {
        if (tempFields.skills.selected) {
          const found = tempFields.skills.selected.find((item) => item === exp);

          if (found) {
            tempFields.skills.selected = tempFields.skills.selected.filter(
              (item) => item !== exp
            );
          } else {
            tempFields.skills.selected.push(exp);
          }

          setCurrentFields(tempFields);
        }
      }
    },
    [currentFields]
  );

  const onChangeExperienceYears = useCallback(
    (value: number | Range) => {
      if (typeof value === "number") {
        const newFields: {
          [key: string]: {
            value: string;
            selected?: string[];
          };
        } = {
          ...currentFields,
          years: {
            ...currentFields.years,
            value: value.toString(),
          },
        };

        setCurrentFields(newFields);
      }
    },
    [currentFields]
  );

  useEffect(() => {
    console.log("Data", currentFields);
  }, [currentFields]);

  return (
    <InnerContent
      overallText={<FormattedMessage id="experienceAndSkills.overallText" />}
      title={<FormattedMessage id="experienceAndSkills.title" />}
    >
      <div className={classes.ExperienceAndSkills}>
        <div className={classes["ExperienceAndSkills-content"]}>
          <RangeSlider
            title={<FormattedMessage id="experienceYears" />}
            max={MAX_YEARS}
            onChange={(value: number | Range) => onChangeExperienceYears(value)}
            value={+currentFields.years.value}
          />
          <CheckboxGroup
            title={<FormattedMessage id="yourExperience.title" />}
            description={<FormattedMessage id="yourExperience.description" />}
            items={[
              {
                label: <FormattedMessage id="ownedAdopted" />,
                onChange: () => onCheckOption("owned", FEATURE.experience),
                id: "ownedAdopted",
                checked: Boolean(
                  currentFields.experience.selected &&
                    currentFields.experience.selected.includes("owned")
                ),
              },
              {
                label: <FormattedMessage id="watcher" />,
                onChange: () => onCheckOption("watcher", FEATURE.experience),
                id: "watcher",
                checked: Boolean(
                  currentFields.experience.selected &&
                    currentFields.experience.selected.includes("watcher")
                ),
              },
              {
                label: <FormattedMessage id="volunteered" />,
                onChange: () =>
                  onCheckOption("volunteered", FEATURE.experience),
                id: "volunteered",
                checked: Boolean(
                  currentFields.experience.selected &&
                    currentFields.experience.selected.includes("volunteered")
                ),
              },
              {
                label: <FormattedMessage id="professional" />,
                onChange: () =>
                  onCheckOption("professional", FEATURE.experience),
                id: "professional",
                checked: Boolean(
                  currentFields.experience.selected &&
                    currentFields.experience.selected.includes("professional")
                ),
              },
              {
                label: <FormattedMessage id="other" />,
                onChange: () => onCheckOption("other", FEATURE.experience),
                id: "other",
                checked: Boolean(
                  currentFields.experience.selected &&
                    currentFields.experience.selected.includes("other")
                ),
              },
            ]}
          />
          <CheckboxGroup
            title={<FormattedMessage id="yourSkills.title" />}
            description={<FormattedMessage id="yourSkills.description" />}
            items={[
              {
                label: <FormattedMessage id="postSurgical" />,
                onChange: () => onCheckOption("postSurgical", FEATURE.skill),
                id: "postSurgical",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes("postSurgical")
                ),
              },
              {
                label: <FormattedMessage id="administeringOralMedication" />,
                onChange: () =>
                  onCheckOption("administeringOralMedication", FEATURE.skill),
                id: "administeringOralMedication",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "administeringOralMedication"
                    )
                ),
              },
              {
                label: (
                  <FormattedMessage id="administeringInjectedMedication" />
                ),
                onChange: () =>
                  onCheckOption(
                    "administeringInjectedMedication",
                    FEATURE.skill
                  ),
                id: "administeringInjectedMedication",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "administeringInjectedMedication"
                    )
                ),
              },
              {
                label: <FormattedMessage id="crpFirstAidCertified" />,
                onChange: () =>
                  onCheckOption("crpFirstAidCertified", FEATURE.skill),
                id: "crpFirstAidCertified",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "crpFirstAidCertified"
                    )
                ),
              },
              {
                label: <FormattedMessage id="separationAnxiety" />,
                onChange: () =>
                  onCheckOption("separationAnxiety", FEATURE.skill),
                id: "separationAnxiety",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes("separationAnxiety")
                ),
              },
              {
                label: <FormattedMessage id="aggressionAgainstOtherDogs" />,
                onChange: () =>
                  onCheckOption("aggressionAgainstOtherDogs", FEATURE.skill),
                id: "aggressionAgainstOtherDogs",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "aggressionAgainstOtherDogs"
                    )
                ),
              },
              {
                label: (
                  <FormattedMessage id="aggressionAgainstCatOrOtherSmallAnimals" />
                ),
                onChange: () =>
                  onCheckOption(
                    "aggressionAgainstCatOrOtherSmallAnimals",
                    FEATURE.skill
                  ),
                id: "aggressionAgainstCatOrOtherSmallAnimals",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "aggressionAgainstCatOrOtherSmallAnimals"
                    )
                ),
              },
              {
                label: <FormattedMessage id="aggressionAgainstPeople" />,
                onChange: () =>
                  onCheckOption("aggressionAgainstPeople", FEATURE.skill),
                id: "aggressionAgainstPeople",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "aggressionAgainstPeople"
                    )
                ),
              },
              {
                label: <FormattedMessage id="leashAggression" />,
                onChange: () => onCheckOption("leashAggression", FEATURE.skill),
                id: "leashAggression",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes("leashAggression")
                ),
              },
              {
                label: <FormattedMessage id="foodOrToyPossessiveBehavior" />,
                onChange: () =>
                  onCheckOption("foodOrToyPossessiveBehavior", FEATURE.skill),
                id: "foodOrToyPossessiveBehavior",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes(
                      "foodOrToyPossessiveBehavior"
                    )
                ),
              },
              {
                label: <FormattedMessage id="pulling" />,
                onChange: () => onCheckOption("pulling", FEATURE.skill),
                id: "pulling",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes("pulling")
                ),
              },
              {
                label: <FormattedMessage id="other" />,
                onChange: () => onCheckOption("other", FEATURE.skill),
                id: "other-skills",
                checked: Boolean(
                  currentFields.skills.selected &&
                    currentFields.skills.selected.includes("other")
                ),
              },
            ]}
          />
          <CertificatesList certificates={fakeCertificates} />
        </div>
        <footer className={classes["ExperienceAndSkills-footer"]}>
          <OutlineButton
            onClick={(): void => onPrev()}
            text={<FormattedMessage id="back" />}
          />
          <GreenButton
            onClick={(): void => onNext()}
            text={<FormattedMessage id="next" />}
          />
        </footer>
      </div>
    </InnerContent>
  );
};

export default ExperienceAndSkills;
