import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

type SuggestionType = {
  children: ReactNode;
};

const Suggestion = ({ children }: SuggestionType): JSX.Element => {
  return <small className={classes.Suggestion}>{children}</small>;
};

export default Suggestion;
