import userEditIcon from "../../assets/images/user-edit.svg";
import animalIcon from "../../assets/images/otter.svg";
import mapIcon from "../../assets/images/map.svg";
import bellIcon from "../../assets/images/bell.svg";
import lockIcon from "../../assets/images/lock.svg";
import creditCardIcon from "../../assets/images/credit-card.svg";
import * as routes from "../../shared/routes";
import { FormattedMessage } from "react-intl";

export const LINK_BOXES_ITEMS = [
  {
    id: "personal-info",
    icon: userEditIcon,
    title: <FormattedMessage id="personalInfo.title" />,
    description: <FormattedMessage id="personalInfo.description" />,
    redirectTo: routes.PERSONAL_INFO,
  },
  {
    id: "my-pets",
    icon: animalIcon,
    title: <FormattedMessage id="myPets.title" />,
    description: <FormattedMessage id="myPets.description" />,
    redirectTo: routes.MY_PETS,
  },
  {
    id: "address",
    icon: mapIcon,
    title: <FormattedMessage id="address.title" />,
    description: <FormattedMessage id="address.description" />,
    redirectTo: routes.ADDRESS,
  },
  {
    id: "notifications",
    icon: bellIcon,
    title: <FormattedMessage id="notifications.title" />,
    description: <FormattedMessage id="notifications.description" />,
    redirectTo: routes.NOTIFICATIONS,
  },
  {
    id: "login-and-security",
    icon: lockIcon,
    title: <FormattedMessage id="loginAndSecurity.title" />,
    description: <FormattedMessage id="loginAndSecurity.description" />,
    redirectTo: routes.LOGIN_AND_SECURITY,
  },
  {
    id: "payments-and-payout",
    icon: creditCardIcon,
    title: <FormattedMessage id="paymentsAndPayout.title" />,
    description: <FormattedMessage id="paymentsAndPayout.description" />,
  },
];
