import { RefObject, useEffect, useMemo } from "react";
import { stopBodyScroll } from "./helpers/utilities";

export const useClassNames = (props: string[]): string => {
  const [
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
    c9,
    c10,
    c11,
    c12,
    c13,
    c14,
    c15,
  ] = props;

  return useMemo(() => {
    return [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13, c14, c15]
      .join(" ")
      .trim();
  }, [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13, c14, c15]);
};

// Body scroll lock
export const useBodyScrollLock = (
  lock: boolean,
  isBackdrop?: boolean
): void => {
  useEffect(() => {
    stopBodyScroll(lock, isBackdrop);

    return (): void => {
      stopBodyScroll(false, isBackdrop);
    };
  }, [lock, isBackdrop]);
};

// For clicking outside ref
type Event = MouseEvent | TouchEvent;
export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void
): void => {
  useEffect(() => {
    const listener = (event: Event): void => {
      const el = ref?.current;
      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }
      handler(event);
    };
    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);
    return (): void => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
    // Reload only if ref or handler changes
  }, [ref, handler]);
};
