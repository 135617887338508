// SESSION / AUTH
export const SIGNIN_START = "SIGNIN_START";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

export const RESET_SESSION = "RESET_SESSION";

// USER
export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const FETCH_USER_PETS_START = "FETCH_USER_PETS_START";
export const FETCH_USER_PETS_SUCCESS = "FETCH_USER_PETS_SUCCESS";
export const FETCH_USER_PETS_FAIL = "FETCH_USER_PETS_FAIL";

export const RESET_USER = "RESET_USER";

export const RESET_USER_PETS = "RESET_USER_PETS";

// PET
export const CREATE_PET_START = "CREATE_PET_START";
export const CREATE_PET_SUCCESS = "CREATE_PET_SUCCESS";
export const CREATE_PET_FAIL = "CREATE_PET_FAIL";

export const FETCH_PET_START = "FETCH_PET_START";
export const FETCH_PET_SUCCESS = "FETCH_PET_SUCCESS";
export const FETCH_PET_FAIL = "FETCH_PET_FAIL";

export const UPDATE_PET_START = "UPDATE_PET_START";
export const UPDATE_PET_SUCCESS = "UPDATE_PET_SUCCESS";
export const UPDATE_PET_FAIL = "UPDATE_PET_FAIL";

export const RESET_PET = "RESET_PET";

// FILE
export const CREATE_FILE_START = "CREATE_FILE_START";
export const CREATE_FILE_SUCCESS = "CREATE_FILE_SUCCESS";
export const CREATE_FILE_FAIL = "CREATE_FILE_FAIL";

export const FETCH_FILE_START = "FETCH_FILE_START";
export const FETCH_FILE_SUCCESS = "FETCH_FILE_SUCCESS";
export const FETCH_FILE_FAIL = "FETCH_FILE_FAIL";

export const RESET_FILE = "RESET_FILE";
