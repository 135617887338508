export const aboutLinks = [
  {
    label: "How Floofy works",
    link: "https://www.google.it",
  },
  {
    label: "Contact us",
    link: "https://www.google.it",
  },
  {
    label: "Careers",
    link: "https://www.google.it",
  },
  {
    label: "Press",
    link: "https://www.google.it",
  },
  {
    label: "Our blog",
    link: "https://www.google.it",
  },
];
