import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./styles.module.scss";
import Title from "../../components/Title";
import GreenButton from "../../components/GreenButton";
import { BUTTON_TYPE, INPUT_TYPE } from "../../shared/enums";
import { FormattedMessage } from "react-intl";
import { SessionType, SigninDataType } from "../../shared/types";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import Input from "../../components/Input";
import { isValidEmail } from "../../shared/validation";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import * as routes from "../../shared/routes";
import { checkSession } from "../../shared/helpers/session";

let FIELDS: {
  [key: string]: {
    value: string;
    selected?: string[];
  };
} = {
  email: {
    value: "",
  },
  password: {
    value: "",
  },
};

type SigninProps = {
  onSignin(data: SigninDataType): SessionType;
  waitingForSignin: boolean;
  sessionError: string;
  me: SessionType;
};

const Signin = ({
  onSignin,
  waitingForSignin = false,
  sessionError = "",
  me,
}: SigninProps) => {
  const history = useHistory();

  const [currentFields, setCurrentFields] = useState(FIELDS);

  const onHandleChangeValue = useCallback(
    (name: string, value: string) => {
      const newFields: {
        [key: string]: {
          value: string;
          selected?: string[];
        };
      } = {
        ...currentFields,
        [name]: {
          ...currentFields[name],
          value,
        },
      };

      setCurrentFields(newFields);

      return true;
    },
    [currentFields]
  );

  const submitDisabled = useMemo(() => {
    return (
      !currentFields.password.value.trim() ||
      !isValidEmail(currentFields.email.value)
    );
  }, [currentFields]);

  const handleSignin = useCallback(() => {
    const data = {
      email: currentFields.email.value,
      password: currentFields.password.value,
    };

    onSignin(data);
  }, [onSignin, currentFields]);

  useEffect(() => {
    if (me && checkSession()) {
      history.push(routes.ACCOUNT);
    }
  }, [me, history]);

  return (
    <div className={classes.Account}>
      <header className={classes["Account-header"]}>
        <Title>
          <FormattedMessage id="general.signin" />
        </Title>
      </header>
      <section className={classes["Account-content"]}>
        <Input
          label={<FormattedMessage id="email" />}
          value={currentFields.email.value}
          onChange={(value: string): boolean =>
            onHandleChangeValue("email", value)
          }
          error={sessionError}
        />
        <Input
          label={<FormattedMessage id="label.password" />}
          value={currentFields.password.value}
          type={INPUT_TYPE.password}
          onChange={(value: string): boolean =>
            onHandleChangeValue("password", value)
          }
          error={sessionError}
        />
        <GreenButton
          disabled={submitDisabled}
          onClick={handleSignin}
          text={<FormattedMessage id="general.signin" />}
        />
        <div className={classes["Account-loader"]}>
          {waitingForSignin && <Loader />}
        </div>
      </section>
      <footer className={classes["Account-footer"]}>
        <h4>
          <FormattedMessage id="deactivate.title" />
        </h4>
        <GreenButton
          type={BUTTON_TYPE.secondary}
          text={<FormattedMessage id="deactivate.cta" />}
          onClick={(): void => alert("Available soon!")}
        />
      </footer>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    me: state.sessionState.session,
    currentRole: state.sessionState.currentRole,
    waitingForSignin: state.sessionState.isFetching,
    sessionError: state.sessionState.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onSignin: (data: SigninDataType) => dispatch(actions.signin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
