import React, { useCallback, useMemo, useState } from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import { INPUT_TYPE } from "../../shared/enums";
import Input from "../../components/Input";
import {
  AdditionalRateType,
  ServiceDataType,
  ServiceType,
} from "../../shared/types";
import { useClassNames } from "../../shared/hooks";
import Checkbox from "../Checkbox";
import ServiceName from "../ServiceName";

// TODO: Check if for mobile version is the same height!
const ITEM_HEIGHT = 75;

type ServiceProps = ServiceType & {
  onCallbackData(data: ServiceDataType): void;
  defaultPrice?: number | null;
  currency?: string;
};

// TODO: Modal for mobile and desktop for additional rates

const Service = ({
  imageUrl,
  name,
  descriptionId,
  labelId,
  onCallbackData,
  defaultPrice = null,
  currency = "€",
  additionalRates = [],
}: ServiceProps) => {
  const [price, setPrice] = useState(defaultPrice);
  const [additionalData, setAdditionData] = useState<
    AdditionalRateType[] | null
  >(
    Boolean(additionalRates?.length)
      ? additionalRates.map((item) => {
          return {
            name: item.name,
            labelId: item.labelId,
            checked: false,
            price: null,
            currency: "€",
          };
        })
      : null
  );
  const [additionalExpanded, setAdditionalExpanded] = useState(false);

  const onHandleCallbackData = useCallback(
    (value: number) => {
      onCallbackData({
        name,
        price: value,
        currency,
      });
    },
    [currency, name, onCallbackData]
  );

  const onSetPrice = useCallback(
    (value: number) => {
      setPrice(value);
      onHandleCallbackData(value);
    },
    [onHandleCallbackData]
  );

  const onToggleAccordion = useCallback(
    () => setAdditionalExpanded(!additionalExpanded),
    [additionalExpanded]
  );

  const serviceClassNames = useClassNames([
    classes.Service,
    additionalData ? classes["Service--noBottomPadding"] : "",
  ]);

  const currentAccordionHeight = useMemo(() => {
    return additionalExpanded && Boolean(additionalRates?.length)
      ? ITEM_HEIGHT * additionalRates?.length
      : 0;
  }, [additionalExpanded, additionalRates]);

  const onToggleAdditionalRate = useCallback(
    (name: string) => {
      if (additionalData) {
        const newAdditionalData = [...additionalData].map((item) => {
          return {
            ...item,
            checked: item.name === name ? !item.checked : item.checked,
            price: item.name === name && item.checked ? null : item.price,
          };
        });

        setAdditionData(newAdditionalData);
      }
    },
    [additionalData]
  );

  const onChangeAdditionalRatePrice = useCallback(
    (name: string, value: number | null) => {
      if (additionalData) {
        const newAdditionalData = [...additionalData].map((item) => {
          return {
            ...item,
            price: item.name === name ? value : item.price,
          };
        });

        setAdditionData(newAdditionalData);
      }
    },
    [additionalData]
  );

  return (
    <article className={serviceClassNames}>
      <div className={classes["Service-content"]}>
        <ServiceName
          label={<FormattedMessage id={labelId} />}
          imageSrc={imageUrl}
        />
        <div className={classes["Service-field"]}>
          <Input
            noMargin={true}
            label={<FormattedMessage id="label.price" values={{ currency }} />}
            value={price || ""}
            type={INPUT_TYPE.number}
            onChange={(value: number): void => onSetPrice(value)}
            placeholder="10"
          />
        </div>
      </div>
      {descriptionId && (
        <p className={classes["Service-description"]}>
          <FormattedMessage id={descriptionId} />
        </p>
      )}
      {additionalData && (
        <div className={classes["Service-additional"]}>
          <button
            onClick={onToggleAccordion}
            className={classes["Service-additional"]}
          >
            <FormattedMessage id="additionalRates" />
          </button>
          <ul
            style={{ height: currentAccordionHeight }}
            className={classes["Service-accordion"]}
          >
            {additionalData.map((add) => {
              return (
                <li key={add.name}>
                  <Checkbox
                    checked={add.checked}
                    id={add.name}
                    label={<FormattedMessage id={add.labelId} />}
                    onChange={(): void => onToggleAdditionalRate(add.name)}
                  />
                  <div className={classes["Service-field"]}>
                    <Input
                      disabled={!add.checked}
                      noMargin={true}
                      label={
                        <FormattedMessage
                          id="label.price"
                          values={{ currency }}
                        />
                      }
                      value={add.price || ""}
                      type={INPUT_TYPE.number}
                      onChange={(value: number): void =>
                        onChangeAdditionalRatePrice(add.name, value)
                      }
                      placeholder="10"
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </article>
  );
};

export default Service;
