import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import EmptyBox from "../EmptyBox";
import { useClassNames } from "../../shared/hooks";
import Suggestion from "../Suggestion";
import plusIcon from "../../assets/icons/add.svg";

export type FileUploaderProps = {
  title?: string | ReactNode;
  uploaderIconUrl?: string;
  contentText: string | ReactNode;
  suggestion?: ReactNode | string;
  fullWidth?: boolean;
  onClick(): void;
};

const FileUploader = ({
  title,
  uploaderIconUrl = plusIcon,
  contentText,
  suggestion,
  fullWidth = false,
  onClick,
}: FileUploaderProps) => {
  const classNames = useClassNames([
    classes.FileUploader,
    fullWidth ? classes["FileUploader--fullWidth"] : "",
  ]);

  return (
    <div onClick={onClick} className={classNames}>
      {title && <h3 className={classes["FileUploader-title"]}>{title}</h3>}
      <div className={classes["FileUploader-content"]}>
        <EmptyBox
          iconUrl={uploaderIconUrl}
          onClick={(): void => console.log("Open dialog for uploading image")}
          text={contentText}
        />
      </div>
      {suggestion && <Suggestion>{suggestion}</Suggestion>}
    </div>
  );
};

export default FileUploader;
