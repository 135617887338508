import React from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";

type FilePreviewType = {
  url?: string;
  name: string;
  onEdit(): void;
};

const FilePreview = ({
  name,
  url,
  onEdit,
}: FilePreviewType): JSX.Element | null => {
  return (
    <article className={classes.FilePreview}>
      <div className={classes["FilePreview-background"]}>
        <div className={classes["FilePreview-overlay"]} />
        {url && (
          <img className={classes["FilePreview-image"]} alt={name} src={url} />
        )}
      </div>
      <div className={classes["FilePreview-data"]}>
        <h3 className={classes["FilePreview-name"]}>{name}</h3>
        <footer className={classes["FilePreview-footer"]}>
          <button className={classes["FilePreview-remove"]}>
            <FormattedMessage id="remove" />
          </button>
          <div>
            <a
              href={url}
              download
              target="_blank"
              rel="noreferrer"
              className={classes["FilePreview-button"]}
            >
              <FormattedMessage id="view" />
            </a>
            <button onClick={onEdit} className={classes["FilePreview-button"]}>
              <FormattedMessage id="edit" />
            </button>
          </div>
        </footer>
      </div>
    </article>
  );
};

export default FilePreview;
