import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { COLOR } from "../../shared/enums";

type TitleType = {
  children: ReactNode;
  color?: COLOR;
};

const Title = ({ children, color }: TitleType): JSX.Element => {
  const classNames = [
    classes.Title,
    color ? classes[`is-${COLOR[color]}`] : "",
  ].join(" ");

  return <h1 className={classNames}>{children}</h1>;
};

export default Title;
