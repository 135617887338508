import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { BUTTON_TYPE } from "../../shared/enums";
import { useClassNames } from "../../shared/hooks";

type GreenButtonProps = {
  text?: string | ReactNode;
  onClick(): void;
  type?: BUTTON_TYPE;
  disabled?: boolean;
  fullWidth?: boolean;
};

const GreenButton = ({
  text,
  onClick,
  type = BUTTON_TYPE.primary,
  disabled = false,
  fullWidth = false,
}: GreenButtonProps) => {
  const classNames = useClassNames([
    classes.GreenButton,
    type ? classes[`GreenButton--${BUTTON_TYPE[type]}`] : "",
    fullWidth ? classes["GreenButton--fullWidth"] : "",
  ]);
  return (
    <button disabled={disabled} className={classNames} onClick={onClick}>
      {text}
    </button>
  );
};

export default GreenButton;
