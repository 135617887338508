import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

export type BlockProps = {
  title: string | ReactNode;
  description: string | ReactNode;
  children?: ReactNode;
};

const Block = ({ title, description, children }: BlockProps) => {
  return (
    <section className={classes.Block}>
      <h3 className={classes["Block-title"]}>{title}</h3>
      <p className={classes["Block-description"]}>{description}</p>
      {children && <div className={classes["Block-content"]}>{children}</div>}
    </section>
  );
};

export default Block;
