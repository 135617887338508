import React, { ReactNode, useCallback, useMemo, useState } from "react";
import classes from "./styles.module.scss";
import GreenButton from "../GreenButton";
import { BUTTON_TYPE } from "../../shared/enums";
import Input from "../Input";
import { useClassNames } from "../../shared/hooks";
import { FormattedMessage } from "react-intl";

export type FieldProps = {
  label: string | ReactNode;
  value: string;
  onSave?: (value: string | number) => void;
  editText?: string | ReactNode;
  error?: string;
  disabled?: boolean;
};

const Field = ({
  label,
  value,
  onSave,
  error,
  disabled = false,
  editText = <FormattedMessage id="edit" />,
}: FieldProps) => {
  const [edit, setEdit] = useState(false);
  const [tempValue, setTempValue] = useState(value);

  const onHandleClickEdit = useCallback(() => setEdit(!edit), [edit]);

  const onHandleClickSave = useCallback(() => {
    setEdit(false);
    if (onSave) onSave(tempValue);
  }, [onSave, tempValue]);

  const onHandleChangeValue = useCallback((value: string) => {
    setTempValue(value);
  }, []);

  const errorText = useMemo(() => {
    return value.trim().length > 0 && tempValue.trim().length === 0 ? (
      <FormattedMessage id="field.mandatory" />
    ) : (
      ""
    );
  }, [value, tempValue]);

  const classNames = useClassNames([
    classes.Field,
    !onSave ? classes["Field--readOnly"] : "",
  ]);

  return (
    <section className={classNames}>
      {edit ? (
        <div className={classes["Field-form"]}>
          <Input
            label={label}
            value={tempValue}
            onChange={onHandleChangeValue}
            error={errorText}
          />
          <footer>
            <GreenButton
              disabled={Boolean(errorText) || disabled}
              text={<FormattedMessage id="save" />}
              onClick={onHandleClickSave}
            />
          </footer>
        </div>
      ) : (
        <>
          <div className={classes["Field-data"]}>
            <label>{label}</label>
            <span>{tempValue}</span>
          </div>
          {onSave && (
            <GreenButton
              disabled={disabled}
              type={BUTTON_TYPE.secondary}
              text={editText}
              onClick={onHandleClickEdit}
            />
          )}
        </>
      )}
    </section>
  );
};

export default Field;
