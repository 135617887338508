import classes from "./styles.module.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CertificateType } from "../../shared/types";
import Grid from "../Grid";
import { FormattedMessage } from "react-intl";
import FilePreview from "../FilePreview";
import FileUploader from "../FileUploader";
import { useOnClickOutside } from "../../shared/hooks";
import UploadModal from "../UploadModal";

type CertificatesListProps = {
  certificates: CertificateType[];
};

const CertificatesList = ({
  certificates = [],
}: CertificatesListProps): JSX.Element | null => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [showModal, setModalVisibility] = useState(false);
  const [currentDoc, setCurrentDoc] = useState<CertificateType | null>(null);

  const onHandleToggleModalVisibility = useCallback(
    () => setModalVisibility(!showModal),
    [showModal]
  );

  const onEdit = useCallback((item: CertificateType) => {
    console.log("Open modal for ID", item.id);
    setCurrentDoc(item);

    setModalVisibility(true);
  }, []);

  // Logic for clicking outside wrapper and closing popup (Only Cart, NO alert popup)
  const handleClickOutsideOverlay = useCallback((): void => {
    setModalVisibility(false);
  }, []);

  useOnClickOutside(modalRef, handleClickOutsideOverlay);

  useEffect(() => {
    if (!showModal) {
      setCurrentDoc(null);
    }
  }, [showModal]);

  return (
    <section className={classes.CertificatesList}>
      <Grid title={<FormattedMessage id="certificate.upload" />}>
        {certificates.map((item) => (
          <FilePreview
            onEdit={(): void => onEdit(item)}
            key={item.id}
            url={item.url}
            name={item.name}
          />
        ))}
        <FileUploader
          onClick={onHandleToggleModalVisibility}
          contentText={<FormattedMessage id="certificate.add" />}
        />
      </Grid>
      {showModal && (
        <UploadModal
          title={
            <FormattedMessage
              id={currentDoc ? "certificate.edit" : "certificate.add"}
            />
          }
          onClose={onHandleToggleModalVisibility}
          reference={modalRef}
          fileName={currentDoc ? currentDoc.name : ""}
          label={<FormattedMessage id="label.certificate" />}
        />
      )}
    </section>
  );
};

export default CertificatesList;
