export const randomId = (): string => {
  return "-" + Math.random().toString(36).substr(2, 18);
};

const NO_SCROLL_CLASS_FOR_OVERLAY = "no-scroll-for-overlay";
const NO_SCROLL_CLASS_FOR_BACKDROP = "no-scroll-for-backdrop";

const disableBodyScroll = (isBackdrop?: boolean): void => {
  if (typeof window !== "undefined" && window) {
    const body = document.querySelector("body") as HTMLElement;
    body.classList.add(
      isBackdrop ? NO_SCROLL_CLASS_FOR_BACKDROP : NO_SCROLL_CLASS_FOR_OVERLAY
    );
  }
};

const enableBodyScroll = (isBackdrop?: boolean): void => {
  if (typeof window !== "undefined" && window) {
    const body = document.querySelector("body") as HTMLElement;
    body.classList.remove(
      isBackdrop ? NO_SCROLL_CLASS_FOR_BACKDROP : NO_SCROLL_CLASS_FOR_OVERLAY
    );
  }
};

/*
 * NO scroll that supports iOS behavior on body scroll.
 * How to use:
 * - Open global.css and add a refer for new off canvas/overlay class
 * example of class: body.no-scroll-for-cart
 *
 * In the component:
 *
 * useEffect(() => {
 *  stopBodyScroll(overlayVisible, 'cart');
 * });
 *
 * It works only for mobile!
 *
 */
export const stopBodyScroll = (status: boolean, isBackdrop?: boolean): void => {
  if (status) {
    return disableBodyScroll(isBackdrop);
  }
  return enableBodyScroll(isBackdrop);
};

export const updateObject = (oldState: any, updatedObject: any) => {
  return {
    ...oldState,
    ...updatedObject,
  };
};
