import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

export type GridProps = {
  title?: ReactNode | string;
  children?: ReactNode;
};

const Grid = ({ title, children }: GridProps) => {
  return (
    <section className={classes.Grid}>
      {title && <h3 className={classes["Grid-title"]}>{title}</h3>}
      <div className={classes["Grid-content"]}>{children}</div>
    </section>
  );
};

export default Grid;
