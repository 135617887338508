import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";
import Input from "../../components/Input";
import Group from "../../components/Group";
import RadioGroup from "../../components/RadioGroup";
import { ANSWER } from "../../shared/enums";
import Map from "../../components/Map";
import Select from "../../components/Select";
import { countries, houseTypes } from "../../shared/__mocks__/options";
import { Range } from "react-input-range";
import RangeSlider from "../../components/RangeSlider";

const MAX_DISTANCE = 50;

let FIELDS: {
  [key: string]: {
    value: string;
    selected?: string[];
  };
} = {
  addressLine1: {
    value: "",
  },
  addressLine2: {
    value: "",
  },
  city: {
    value: "",
  },
  postCode: {
    value: "",
  },
  country: {
    value: "",
  },
  houseType: {
    value: "",
  },
  yourNeighbourhood: {
    value: "20",
  },
  anyDog: {
    value: "",
  },
  anyCat: {
    value: "",
  },
  availabilityFullTime: { value: "" },
  multiPets: { value: "" },
  smoke: { value: "" },
  petsOnYourBed: { value: "" },
  petsOnFurniture: { value: "" },
  garden: { value: "" },
};

type YourHomeProps = {
  onNext(): void;
  onPrev(): void;
};

const YourHome = ({ onNext, onPrev }: YourHomeProps) => {
  const [currentFields, setCurrentFields] = useState(FIELDS);

  const onHandleChangeValue = useCallback(
    (name: string, value: string) => {
      const newFields: {
        [key: string]: {
          value: string;
          selected?: string[];
        };
      } = {
        ...currentFields,
        [name]: {
          ...currentFields[name],
          value,
        },
      };

      setCurrentFields(newFields);

      return true;
    },
    [currentFields]
  );

  const onChangeDistance = useCallback(
    (value: number | Range) => {
      if (typeof value === "number") {
        const newFields: {
          [key: string]: {
            value: string;
            selected?: string[];
          };
        } = {
          ...currentFields,
          yourNeighbourhood: {
            ...currentFields.yourNeighbourhood,
            value: value.toString(),
          },
        };

        setCurrentFields(newFields);
      }
    },
    [currentFields]
  );

  useEffect(() => {
    console.log("Data", currentFields);
  }, [currentFields]);

  return (
    <InnerContent
      overallText={<FormattedMessage id="yourHome.overallText" />}
      title={<FormattedMessage id="yourHome.title" />}
      description={<FormattedMessage id="yourHome.description" />}
      onHandleBack={onPrev}
      onHandleNext={onNext}
    >
      <div className={classes.YourHome}>
        <div className={classes["YourHome-content"]}>
          <Group>
            <Input
              label={<FormattedMessage id="addressLine1" />}
              value={currentFields.addressLine1.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("addressLine1", value)
              }
            />
          </Group>
          <Group>
            <Input
              label={<FormattedMessage id="addressLine2" />}
              value={currentFields.addressLine2.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("addressLine2", value)
              }
            />
          </Group>
          <Group numberOfColumns={2}>
            <Input
              label={<FormattedMessage id="city" />}
              value={currentFields.city.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("city", value)
              }
            />
            <Input
              label={<FormattedMessage id="postCode" />}
              value={currentFields.postCode.value}
              onChange={(value: string): boolean =>
                onHandleChangeValue("postCode", value)
              }
            />
          </Group>
          <Group>
            <Select
              options={countries}
              onChange={(value: string): boolean =>
                onHandleChangeValue("country", value)
              }
              value={currentFields.country.value}
            />
          </Group>
          <Group>
            <Select
              options={houseTypes}
              onChange={(value: string): boolean =>
                onHandleChangeValue("houseType", value)
              }
              value={currentFields.houseType.value}
            />
          </Group>
          <section className={classes["YourHome-map"]}>
            <Map src="https://www.openstreetmap.org/export/embed.html?bbox=-0.20416975021362307%2C51.537890766118224%2C-0.20149290561676028%2C51.53917535886987&amp;layer=mapnik&amp;marker=51.53853306702627%2C-0.20283132791519165" />
          </section>
          <Group>
            <RangeSlider
              title={<FormattedMessage id="yourNeighbourhoodDistance" />}
              description={
                <FormattedMessage id="yourHome.suggestion.neighbourhood" />
              }
              max={MAX_DISTANCE}
              onChange={(value: number | Range) => onChangeDistance(value)}
              value={+currentFields.yourNeighbourhood.value}
              format="km"
            />
          </Group>
          <RadioGroup
            title={<FormattedMessage id="anyDog.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () => onHandleChangeValue("anyDog", ANSWER.yes),
                id: "yes-any-dog",
                checked: currentFields.anyDog.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("anyDog", ANSWER.no),
                id: "no-any-dog",
                checked: currentFields.anyDog.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="anyCat.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () => onHandleChangeValue("anyCat", ANSWER.yes),
                id: "yes-any-cat",
                checked: currentFields.anyCat.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("anyCat", ANSWER.no),
                id: "no-any-cat",
                checked: currentFields.anyCat.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="availabilityFullTime.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () =>
                  onHandleChangeValue("availabilityFullTime", ANSWER.yes),
                id: "yes-availability",
                checked:
                  currentFields.availabilityFullTime.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () =>
                  onHandleChangeValue("availabilityFullTime", ANSWER.no),
                id: "no-availability",
                checked: currentFields.availabilityFullTime.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="multiPets.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () => onHandleChangeValue("multiPets", ANSWER.yes),
                id: "yes-multiPets",
                checked: currentFields.multiPets.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("multiPets", ANSWER.no),
                id: "no-multiPets",
                checked: currentFields.multiPets.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="smoke.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () => onHandleChangeValue("smoke", ANSWER.yes),
                id: "yes-smoke",
                checked: currentFields.smoke.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("smoke", ANSWER.no),
                id: "no-smoke",
                checked: currentFields.smoke.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="petsOnYourBed.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () =>
                  onHandleChangeValue("petsOnYourBed", ANSWER.yes),
                id: "yes-petsOnYourBed",
                checked: currentFields.petsOnYourBed.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("petsOnYourBed", ANSWER.no),
                id: "no-petsOnYourBed",
                checked: currentFields.petsOnYourBed.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="petsOnFurniture.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () =>
                  onHandleChangeValue("petsOnFurniture", ANSWER.yes),
                id: "yes-petsOnFurniture",
                checked: currentFields.petsOnFurniture.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () =>
                  onHandleChangeValue("petsOnFurniture", ANSWER.no),
                id: "no-petsOnFurniture",
                checked: currentFields.petsOnFurniture.value === ANSWER.no,
              },
            ]}
          />
          <RadioGroup
            title={<FormattedMessage id="garden.question" />}
            items={[
              {
                label: <FormattedMessage id="yes" />,
                onChange: () => onHandleChangeValue("garden", ANSWER.yes),
                id: "yes-garden",
                checked: currentFields.garden.value === ANSWER.yes,
              },
              {
                label: <FormattedMessage id="no" />,
                onChange: () => onHandleChangeValue("garden", ANSWER.no),
                id: "no-garden",
                checked: currentFields.garden.value === ANSWER.no,
              },
            ]}
          />
        </div>
      </div>
    </InnerContent>
  );
};

export default YourHome;
