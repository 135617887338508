import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { useBodyScrollLock, useClassNames } from "../../shared/hooks";

export type BackdropProps = {
  children: ReactNode;
  opacity?: number;
  hasOverflow?: boolean;
};

export default function Backdrop({
  children,
  opacity = 0.5,
  hasOverflow = false,
}: BackdropProps): JSX.Element {
  useBodyScrollLock(true, true);

  const styles = {
    backgroundColor: `rgba(0, 0, 0, ${opacity})`,
  };

  const classNames = useClassNames([
    classes.Backdrop,
    hasOverflow ? classes["Backdrop--withOverflow"] : "",
  ]);

  return (
    <div className={classNames} style={styles}>
      {children}
    </div>
  );
}
