import { combineReducers } from "redux";
import sessionReducer from "./session";
import userReducer from "./user";
import petReducer from "./pet";
import fileReducer from "./file";

export const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userState: userReducer,
  petState: petReducer,
  fileState: fileReducer,
});
