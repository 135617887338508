import React, { ReactNode, useCallback, useState } from "react";
import classes from "./styles.module.scss";
import { useClassNames } from "../../shared/hooks";
import errorIcon from "../../assets/icons/error.svg";
import Suggestion from "../Suggestion";

export type TextareaProps = {
  label: string | ReactNode;
  value?: string;
  onChange(value: string): void;
  error?: string | ReactNode;
  rows?: number;
  suggestionText?: ReactNode | string;
};

const Textarea = ({
  label,
  value = "",
  onChange,
  error,
  rows = 6,
  suggestionText,
}: TextareaProps) => {
  const [focused, setFocused] = useState(false);

  const classNames = useClassNames([
    classes.Textarea,
    focused ? classes["is-focused"] : "",
  ]);

  const inputDataClassNames = useClassNames([
    classes["Textarea-data"],
    Boolean(error) ? classes["Textarea-data--error"] : "",
  ]);

  const feedbackClassNames = useClassNames([
    classes["Textarea-feedback"],
    Boolean(error) ? classes["is-visible"] : "",
  ]);

  const onHandleChange = useCallback(
    (value: string) => {
      onChange(value);
      return true;
    },
    [onChange]
  );

  const onToggleFocus = useCallback((isFocused: boolean) => {
    setFocused(isFocused);
  }, []);

  return (
    <div className={classNames}>
      <div className={inputDataClassNames}>
        <label>{label}</label>
        <textarea
          rows={rows}
          onChange={(e): boolean => onHandleChange(e.currentTarget.value)}
          onFocus={(): void => onToggleFocus(true)}
          onBlur={(): void => onToggleFocus(false)}
          value={value || ""}
        />
      </div>
      <small className={feedbackClassNames}>
        {error && (
          <>
            <img src={errorIcon} alt="error" />
            {error}
          </>
        )}
      </small>
      {suggestionText && <Suggestion>{suggestionText}</Suggestion>}
    </div>
  );
};

export default Textarea;
