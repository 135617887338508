import React, { ReactNode, useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";
import { services } from "../../shared/__mocks__/services";
import ServiceName from "../../components/ServiceName";
import Checkbox from "../../components/Checkbox";
import RangeSlider from "../../components/RangeSlider";
import { Range } from "react-input-range";

type ServicesAvailabilityProps = {
  onNext(): void;
  onPrev(): void;
};

const MAX_PET = 5;

const DAYS: {
  dayIndex: number;
  key: string;
  text: ReactNode;
}[] = [
  {
    dayIndex: 1,
    key: "mon",
    text: <FormattedMessage id="monday" />,
  },
  {
    dayIndex: 2,
    key: "tue",
    text: <FormattedMessage id="tuesday" />,
  },
  {
    dayIndex: 3,
    key: "wed",
    text: <FormattedMessage id="wednesday" />,
  },
  {
    dayIndex: 4,
    key: "thu",
    text: <FormattedMessage id="thursday" />,
  },
  {
    dayIndex: 5,
    key: "fri",
    text: <FormattedMessage id="friday" />,
  },
  {
    dayIndex: 6,
    key: "sat",
    text: <FormattedMessage id="saturday" />,
  },
  {
    dayIndex: 0,
    key: "sun",
    text: <FormattedMessage id="sunday" />,
  },
];

let FIELDS: {
  maxPets: {
    [key: string]: number;
  };
  days: {
    selected: {
      [key: string]: string[];
    };
  };
} = {
  maxPets: {},
  days: {
    selected: {},
  },
};

const ServicesAvailability = ({
  onNext,
  onPrev,
}: ServicesAvailabilityProps) => {
  const [currentFields, setCurrentFields] = useState(FIELDS);

  const onChangeNumberOfPets = useCallback(
    (value: number | Range, service: string) => {
      if (typeof value === "number") {
        const newFields = {
          ...currentFields,
          maxPets: {
            ...currentFields.maxPets,
            [service]: value,
          },
          days: {
            ...currentFields.days,
          },
        };

        setCurrentFields(newFields);
      }
    },
    [currentFields]
  );

  const onCheckDay = useCallback(
    (exp: string, service: string) => {
      let tempFields = { ...currentFields };

      if (tempFields.days.selected?.hasOwnProperty(service)) {
        let newSelected = [...tempFields.days.selected[service]];

        const dayFound = newSelected.find((item) => item === exp);

        if (dayFound) {
          newSelected = newSelected.filter((item) => item !== exp);
        } else {
          newSelected.push(exp);
        }

        tempFields = {
          ...tempFields,
          days: {
            selected: {
              ...tempFields.days.selected,
              [service]: newSelected,
            },
          },
        };
      } else {
        tempFields = {
          ...tempFields,
          days: {
            selected: {
              ...tempFields.days.selected,
              [service]: [exp],
            },
          },
        };
      }

      setCurrentFields(tempFields);
    },
    [currentFields]
  );

  useEffect(() => {
    console.log(currentFields);
  }, [currentFields]);

  return (
    <InnerContent
      overallText={<FormattedMessage id="servicesAvailability.overallText" />}
      title={
        <FormattedMessage id="sitterSignup.wizardNavigation.servicesAvailability" />
      }
      onHandleBack={onPrev}
      onHandleNext={onNext}
    >
      <div className={classes.ServicesAvailability}>
        <div className={classes["ServicesAvailability-content"]}>
          <ul>
            {services.map((service, index) => {
              return (
                <li key={index}>
                  <article className={classes.ServicesAvailabilityBlock}>
                    <div className={classes["ServicesAvailabilityBlock-name"]}>
                      <ServiceName
                        label={<FormattedMessage id={service.labelId} />}
                        imageSrc={service.imageUrl}
                      />
                    </div>
                    <div
                      className={classes["ServicesAvailabilityBlock-content"]}
                    >
                      <h3
                        className={
                          classes["ServicesAvailabilityBlock-question"]
                        }
                      >
                        <FormattedMessage id="servicesAvailability.question" />
                      </h3>
                      <div
                        className={classes["ServicesAvailabilityBlock-days"]}
                      >
                        {DAYS.map((day) => {
                          return (
                            <Checkbox
                              key={day.dayIndex}
                              checked={
                                currentFields.days.selected &&
                                currentFields.days.selected[service.name]
                                  ? currentFields.days.selected[
                                      service.name
                                    ].includes(day.key)
                                  : false
                              }
                              id={`day-${service.name}-${day.dayIndex}`}
                              label={day.text}
                              onChange={(): void =>
                                onCheckDay(day.key, service.name)
                              }
                            />
                          );
                        })}
                      </div>
                      <RangeSlider
                        title={
                          <FormattedMessage id="servicesAvailability.experience" />
                        }
                        max={MAX_PET}
                        onChange={(value: number | Range) =>
                          onChangeNumberOfPets(value, service.name)
                        }
                        value={
                          currentFields.maxPets ||
                          currentFields.maxPets[service.name]
                            ? currentFields.maxPets[service.name]
                            : MAX_PET
                        }
                      />
                    </div>
                  </article>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </InnerContent>
  );
};

export default ServicesAvailability;
