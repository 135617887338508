import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { NavigationWizardItem } from "../../shared/types";
import checkIcon from "../../assets/icons/checkmark-circle.svg";

export type NavigationWizardProps = {
  title: string | ReactNode;
  items: NavigationWizardItem[];
  currentId: number;
  onClick(id: number): void;
};

const NavigationWizard = ({
  title,
  items = [],
  currentId,
  onClick,
}: NavigationWizardProps): JSX.Element | null => {
  if (!items || !items.length) return null;

  return (
    <section className={classes.NavigationWizard}>
      <h3 className={classes["NavigationWizard-title"]}>{title}</h3>
      <ul className={classes["NavigationWizard-items"]}>
        {items.map((item, ix) => {
          const itemClassNames = [
            classes["NavigationWizard-item"],
            currentId === item.id ? classes["is-active"] : "",
            //currentId < item.id ? classes["is-disabled"] : "",
          ].join(" ");

          return (
            <li
              key={ix}
              className={itemClassNames}
              onClick={(): void => onClick(item.id)}
            >
              <span>{item.text}</span>
              {item.isCompleted && <img src={checkIcon} alt="completed" />}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default NavigationWizard;
