import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import arrowRightIcon from "../../assets/icons/arrow--right.svg";
import { Link } from "react-router-dom";
import * as routes from "../../shared/routes";

type LinkBoxType = {
  redirectTo?: string;
  icon?: string;
  title: string | ReactNode;
  description: string | ReactNode;
  id: string;
};

const LinkBox = ({
  redirectTo = routes.EMPTY,
  icon,
  title,
  description,
  id,
}: LinkBoxType): JSX.Element => {
  const classNames = [
    classes.LinkBox,
    redirectTo === routes.EMPTY ? classes["is-disabled"] : "",
  ].join(" ");

  return (
    <Link to={redirectTo} className={classNames}>
      {icon && <img className={classes["LinkBox-icon"]} src={icon} alt={id} />}
      <header className={classes["LinkBox-header"]}>
        <h3 className={classes["LinkBox-title"]}>{title}</h3>
        <img src={arrowRightIcon} alt="next" />
      </header>
      <p className={classes["LinkBox-description"]}>{description}</p>
    </Link>
  );
};

export default LinkBox;
