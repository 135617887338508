import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

type ThreeColumnsLayoutProps = {
  firstColumn: ReactNode;
  secondColumn: ReactNode;
  thirdColumn: ReactNode;
};

const ThreeColumnsLayout = ({
  firstColumn,
  secondColumn,
  thirdColumn,
}: ThreeColumnsLayoutProps) => {
  return (
    <section className={classes.ThreeColumnsLayout}>
      <div className={classes["ThreeColumnsLayout-first"]}>{firstColumn}</div>
      <div className={classes["ThreeColumnsLayout-second"]}>{secondColumn}</div>
      <div className={classes["ThreeColumnsLayout-third"]}>{thirdColumn}</div>
    </section>
  );
};

export default ThreeColumnsLayout;
