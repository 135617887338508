import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as routes from "../../shared/routes";
import PrivateRoute from "../../hocs/PrivateRoute";

// Routes
import Account from "../../routes/Account";
import PersonalInfo from "../../routes/PersonalInfo";
import MyPets from "../../routes/MyPets";
import MyPet from "../../routes/MyPet";
import Address from "../../routes/Address";
import LoginSecurity from "../../routes/LoginAndSecurity";
import Notifications from "../../routes/Notifications";
import SitterSignup from "../../routes/SitterSignup";
import Signin from "../../routes/Signin";

const RouterWrapper = (): JSX.Element => {
  return (
    <Switch>
      <Route exact={true} path={routes.SIGNIN} component={Signin} />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.ACCOUNT}
        component={Account}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.PERSONAL_INFO}
        component={PersonalInfo}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.ADDRESS}
        component={Address}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.MY_PETS}
        component={MyPets}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.MY_PETS_EDIT}
        component={MyPet}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.MY_PETS_NEW}
        component={MyPet}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.NOTIFICATIONS}
        component={Notifications}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.LOGIN_AND_SECURITY}
        component={LoginSecurity}
      />
      <PrivateRoute
        redirectTo={routes.SIGNIN}
        exact={true}
        path={routes.SITTER_SIGNUP}
        component={SitterSignup}
      />
      <Redirect to={routes.SIGNIN} />
    </Switch>
  );
};

export default RouterWrapper;
