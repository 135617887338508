import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/helpers/utilities";
import { SessionType } from "../../shared/types";

type ActionType = {
  type: string;
  session: SessionType;
  error: string;
};

const INITIAL_STATE = {
  session: null,
  isFetching: false,
  error: null,
  currentRole: null,
};

const signinStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    session: null,
    currentRole: null,
    error: null,
    isFetching: true,
  });
};

const signinSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    session: action.session,
    isFetching: false,
    currentRole: action?.session?.roles?.[0],
  });
};

const signinFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
  });
};

const resetSessionSuccess = (state = INITIAL_STATE) => {
  return updateObject(state, {
    session: null,
    isFetching: false,
    error: null,
    currentRole: null,
  });
};

const sessionReducer = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    // SIGNIN
    case actionTypes.SIGNIN_START:
      return signinStart(state);
    case actionTypes.SIGNIN_SUCCESS:
      return signinSuccess(state, action);
    case actionTypes.SIGNIN_FAIL:
      return signinFail(state, action);

    // SIGNOUT
    case actionTypes.RESET_SESSION:
      return resetSessionSuccess(state);

    default:
      return state;
  }
};

export default sessionReducer;
