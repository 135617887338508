import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { useClassNames } from "../../shared/hooks";
import Suggestion from "../Suggestion";

export type GroupProps = {
  children: ReactNode;
  suggestion?: ReactNode | string;
  numberOfColumns?: number;
};

const Group = ({
  children,
  suggestion,
  numberOfColumns = 1,
}: GroupProps): JSX.Element => {
  const classNames = useClassNames([
    classes.Group,
    numberOfColumns === 2 ? classes["Group--50"] : "",
    numberOfColumns === 3 ? classes["Group--33"] : "",
  ]);

  return (
    <div className={classNames}>
      <div className={classes["Group-content"]}>{children}</div>
      {suggestion && <Suggestion>{suggestion}</Suggestion>}
    </div>
  );
};

export default Group;
