import React, { ReactNode, useCallback, useState } from "react";
import classes from "./styles.module.scss";
import { useClassNames } from "../../shared/hooks";
import errorIcon from "../../assets/icons/error.svg";
import { INPUT_TYPE } from "../../shared/enums";
import { isNumberOrDecimal } from "../../shared/helpers/regex";

export type InputProps = {
  label: string | ReactNode;
  value?: string | number;
  onChange(value: string | number): void;
  error?: string | ReactNode;
  type?: INPUT_TYPE;
  noMargin?: boolean;
  placeholder?: string;
  disabled?: boolean;
};

const Input = ({
  label,
  value = "",
  onChange,
  error,
  type = INPUT_TYPE.text,
  noMargin = false,
  placeholder = "",
  disabled = false,
}: InputProps) => {
  const [focused, setFocused] = useState(false);

  const classNames = useClassNames([
    classes.Input,
    focused ? classes["is-focused"] : "",
    noMargin ? classes["Input--noMargin"] : "",
  ]);

  const inputDataClassNames = useClassNames([
    classes["Input-data"],
    Boolean(error) ? classes["Input-data--error"] : "",
    disabled ? classes["is-disabled"] : "",
  ]);

  const onHandleChange = useCallback(
    (value: string) => {
      if (type === INPUT_TYPE.number && !isNumberOrDecimal(value)) {
        return false;
      }

      onChange(value);
      return true;
    },
    [onChange, type]
  );

  const onToggleFocus = useCallback((isFocused: boolean) => {
    setFocused(isFocused);
  }, []);

  return (
    <div className={classNames}>
      <div className={inputDataClassNames}>
        <label>{label}</label>
        <input
          disabled={disabled}
          type={type}
          value={value || ""}
          onChange={(e): boolean => onHandleChange(e.currentTarget.value)}
          onFocus={(): void => onToggleFocus(true)}
          onBlur={(): void => onToggleFocus(false)}
          placeholder={placeholder}
        />
      </div>
      {error && (
        <small className={classes["Input-feedback"]}>
          <img src={errorIcon} alt="error" />
          {error}
        </small>
      )}
    </div>
  );
};

export default Input;
