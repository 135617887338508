import React, { useCallback, useEffect } from "react";
import classes from "./styles.module.scss";
import Content from "../../containers/Content";
import { address } from "../../shared/navigations/breadcrumbs";
import Field from "../../components/Field";
import { addressFields } from "../../shared/__mocks__/fields";
import { FormattedMessage } from "react-intl";
import Map from "../../components/Map";
import * as actions from "../../store/actions";
import { UserDataType } from "../../shared/types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCurrentUserId, resetSession } from "../../shared/helpers/session";
import * as routes from "../../shared/routes";

type AddressProps = {
  onGetUser: (id: string) => UserDataType;
  onUpdateUser: (id: string, data: UserDataType) => UserDataType;
  user: Omit<UserDataType, "files">;
  waitingForGettingUser: boolean;
  waitingForUpdatingUser: boolean;
  userError: string;
};

const Address = ({
  onGetUser,
  onUpdateUser,
  user,
  waitingForGettingUser,
  userError,
}: AddressProps) => {
  const history = useHistory();

  useEffect(() => {
    const userId = getCurrentUserId();

    if (!userId) {
      resetSession();
      history.push(routes.SIGNIN);
    } else {
      onGetUser(userId);
    }
  }, [history, onGetUser]);

  const handleSaveField = useCallback(
    (key, value) => {
      const userId = getCurrentUserId();

      if (!userId) {
        resetSession();
        history.push(routes.SIGNIN);
      } else {
        const data = {
          [key]: value,
        };

        onUpdateUser(userId, data);
      }
    },
    [onUpdateUser, history]
  );

  return (
    <Content
      title={<FormattedMessage id="address.title" />}
      breadcrumbsList={address}
    >
      <div className={classes.Address}>
        {user &&
          addressFields.map((field, ix) => {
            return (
              <Field
                disabled={waitingForGettingUser}
                key={ix}
                label={field.label}
                value={
                  user && field.key
                    ? user[field.key as keyof typeof user] || ""
                    : field.value
                }
                onSave={(value: string | number) =>
                  handleSaveField(field.key, value)
                }
              />
            );
          })}
        <Map src="https://www.openstreetmap.org/export/embed.html?bbox=-0.20416975021362307%2C51.537890766118224%2C-0.20149290561676028%2C51.53917535886987&amp;layer=mapnik&amp;marker=51.53853306702627%2C-0.20283132791519165" />
      </div>
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.userState.user,
    waitingForGettingUser: state.userState.isFetching,
    waitingForUpdatingUser: state.userState.isUpdating,
    userError: state.userState.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetUser: (id: string) => dispatch(actions.fetchUser(id)),
    onUpdateUser: (id: string, data: UserDataType) =>
      dispatch(actions.updateUser(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
