import request from "../../shared/lib/request";
import constants from "../../shared/constants";
import { PetDataFieldsType, PetDataType } from "../../shared/types";

const get = (id: string) => {
  const authToken = localStorage.getItem(
    constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN
  );

  let headers: any = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const url = `/api/v1/pet/${id}`;

  return request({
    url,
    method: "GET",
    headers,
  });
};

const create = (data: PetDataType) => {
  const authToken = localStorage.getItem(
    constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN
  );

  let headers: any = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  return request({
    url: "/api/v1/pet",
    method: "POST",
    headers,
    data,
  });
};

const update = (id: string, data: PetDataFieldsType) => {
  const authToken = localStorage.getItem(
    constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN
  );

  let headers: any = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };

  const url = `/api/v1/pet/${id}`;

  return request({
    url,
    method: "PATCH",
    headers,
    data,
  });
};

const PetsService = {
  get,
  create,
  update,
};

export default PetsService;
