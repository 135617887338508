import React, { useCallback, useState } from "react";
import classes from "./styles.module.scss";
import GreenButton from "../GreenButton";
import { BUTTON_TYPE } from "../../shared/enums";
import Input from "../Input";
import { useClassNames } from "../../shared/hooks";
import OutlineButton from "../OutlineButton";
import { FormattedMessage } from "react-intl";

export type PasswordUpdateProps = {
  onSave?(): void;
};

const PasswordUpdate = ({ onSave }: PasswordUpdateProps) => {
  const [update, setUpdate] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onHandleClickEdit = useCallback(() => setUpdate(!update), [update]);

  const onHandleClickSave = useCallback(() => {
    setUpdate(false);
    if (onSave) onSave();
  }, [onSave]);

  const onHandleChangeValue = useCallback((value: string, field: string) => {
    if (field === "current") {
      setCurrentPassword(value);
    }

    if (field === "new") {
      setNewPassword(value);
    }

    if (field === "confirm") {
      setConfirmPassword(value);
    }
  }, []);

  const classNames = useClassNames([
    classes.PasswordUpdate,
    !onSave ? classes["PasswordUpdate--readOnly"] : "",
    update ? classes["PasswordUpdate--hasForm"] : "",
  ]);

  return (
    <section className={classNames}>
      <div className={classes["PasswordUpdate-data"]}>
        <label>
          <FormattedMessage id="password" />
        </label>
        {!update && (
          <GreenButton
            type={BUTTON_TYPE.secondary}
            text={<FormattedMessage id="update" />}
            onClick={onHandleClickEdit}
          />
        )}
      </div>
      {update && (
        <div className={classes["PasswordUpdate-form"]}>
          <Input
            label={<FormattedMessage id="currentPassword" />}
            value={currentPassword}
            onChange={(value: string): void =>
              onHandleChangeValue(value, "current")
            }
          />
          <Input
            label={<FormattedMessage id="newPassword" />}
            value={newPassword}
            onChange={(value: string): void =>
              onHandleChangeValue(value, "new")
            }
          />
          <Input
            label={<FormattedMessage id="confirmPassword" />}
            value={confirmPassword}
            onChange={(value: string): void =>
              onHandleChangeValue(value, "confirm")
            }
          />
          <footer>
            <OutlineButton
              text={<FormattedMessage id="cancel" />}
              onClick={onHandleClickEdit}
            />
            <GreenButton
              text={<FormattedMessage id="save" />}
              onClick={onHandleClickSave}
            />
          </footer>
        </div>
      )}
    </section>
  );
};

export default PasswordUpdate;
