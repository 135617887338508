import * as routes from "../routes";
import { FormattedMessage } from "react-intl";

export const personalInfo = [
  {
    to: routes.ACCOUNT,
    children: <FormattedMessage id="general.account" />,
  },
  {
    to: routes.PERSONAL_INFO,
    children: <FormattedMessage id="personalInfo.title" />,
  },
];

export const myPets = [
  {
    to: routes.ACCOUNT,
    children: <FormattedMessage id="general.account" />,
  },
  {
    to: routes.MY_PETS,
    children: <FormattedMessage id="myPets.title" />,
  },
];

export const loginSecurity = [
  {
    to: routes.ACCOUNT,
    children: <FormattedMessage id="general.account" />,
  },
  {
    to: routes.LOGIN_AND_SECURITY,
    children: <FormattedMessage id="loginAndSecurity.title" />,
  },
];

export const address = [
  {
    to: routes.ACCOUNT,
    children: <FormattedMessage id="general.account" />,
  },
  {
    to: routes.ADDRESS,
    children: <FormattedMessage id="address.title" />,
  },
];

export const notifications = [
  {
    to: routes.ACCOUNT,
    children: <FormattedMessage id="general.account" />,
  },
  {
    to: routes.NOTIFICATIONS,
    children: <FormattedMessage id="notifications.title" />,
  },
];
