import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classes from "./styles.module.scss";
import EmptyBox from "../EmptyBox";
import { useClassNames } from "../../shared/hooks";
import Suggestion from "../Suggestion";
import * as actions from "../../store/actions";
import { FileDataType } from "../../shared/types";
import { connect } from "react-redux";

export type PhotoUploaderProps = {
  title?: string | ReactNode;
  src?: string | null;
  alternativeText?: string;
  uploaderIconUrl?: string;
  contentText: string | ReactNode;
  suggestion?: ReactNode | string;
  fullWidth?: boolean;
  files?: FileDataType[];
  isCreatingFile?: boolean;
  error?: string;
  onCreateFile: (file: FileDataType) => FileDataType[];
  onCallbackFile?: (files: FileDataType[]) => void;
  tag?: string;
  multiple?: boolean;
};

const PhotoUploader = ({
  title,
  src,
  alternativeText = "picture",
  uploaderIconUrl,
  contentText,
  suggestion,
  fullWidth = false,
  files,
  onCreateFile,
  isCreatingFile,
  tag,
  onCallbackFile,
  multiple = false,
}: PhotoUploaderProps) => {
  const [tempFiles, setTempFiles] = useState<File[] | null>([]);
  const imageUploaderRef = useRef<HTMLInputElement>(null);
  const classNames = useClassNames([
    classes.PhotoUploader,
    fullWidth ? classes["PhotoUploader--fullWidth"] : "",
  ]);

  const handleUploadImage = useCallback(() => {
    if (tempFiles?.length) {
      const formData = new FormData();
      const files = Array.from(tempFiles);
      files.forEach((item) => {
        formData.append(item.name, item);
      });
      if (tag) {
        formData.append("tags", tag);
      }
      onCreateFile({ content: formData });
    }
  }, [tempFiles, onCreateFile, tag]);

  const handleClickImage = () => {
    //@ts-ignore
    imageUploaderRef?.current?.click();
  };

  const handleChangeUploadFile = useCallback((event) => {
    setTempFiles(event.target.files);
  }, []);

  useEffect(() => {
    if (tempFiles?.length) {
      handleUploadImage();

      setTempFiles([]);
    }
  }, [tempFiles, handleUploadImage]);

  useEffect(() => {
    if (files?.length && onCallbackFile) {
      onCallbackFile(files);
    }
  }, [files, onCallbackFile]);

  return (
    <section className={classNames}>
      {title && <h3 className={classes["PhotoUploader-title"]}>{title}</h3>}
      <div
        className={classes["PhotoUploader-content"]}
        onClick={handleClickImage}
      >
        {src ? (
          <img src={src} alt={alternativeText} />
        ) : (
          <EmptyBox
            iconUrl={uploaderIconUrl}
            onClick={(): void => console.log("Open dialog for uploading image")}
            text={contentText}
          />
        )}
      </div>
      <input
        className={classes["PhotoUploader-input"]}
        type="file"
        ref={imageUploaderRef}
        onChange={handleChangeUploadFile}
        multiple={multiple}
      />
      {suggestion && <Suggestion>{suggestion}</Suggestion>}
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    files: state.fileState.files,
    waitingForCreating: state.fileState.isFetching,
    error: state.fileState.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onCreateFile: (file: FileDataType) => dispatch(actions.createFile(file)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoUploader);
