import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import Content from "../../containers/Content";
import { FormattedMessage } from "react-intl";
import { notifications } from "../../shared/navigations/breadcrumbs";
import { fields } from "../../shared/data/notifications";
import Block from "../../components/Block";
import { notificationsBlocks } from "../../shared/__mocks__/fields";
import SwitchRow from "../../components/SwitchRow";

const Notifications = () => {
  const [tempValues, setTempValues] = useState(fields);

  const onHandleCheck = useCallback(
    (value: boolean, section: string, property: string) => {
      const newTempValues = {
        ...tempValues,
        [section]: {
          ...tempValues[section],
          [property]: value,
        },
      };

      setTempValues(newTempValues);
    },
    [tempValues]
  );

  useEffect(() => {
    console.log("New values to send to API EP", tempValues);

    // Call API to patch data with new values
  }, [tempValues]);

  return (
    <Content
      title={<FormattedMessage id="notifications.title" />}
      breadcrumbsList={notifications}
    >
      <div className={classes.Notifications}>
        {notificationsBlocks.map((block, ix) => {
          return (
            <Block key={ix} title={block.title} description={block.description}>
              <section>
                {block.contentFields &&
                  block.contentFields.length > 0 &&
                  block.contentFields.map((field, i) => (
                    <SwitchRow
                      key={i}
                      text={field.text}
                      checked={tempValues[block.key][field.key]}
                      onChange={(checked: boolean): void =>
                        onHandleCheck(checked, block.key, field.key)
                      }
                    />
                  ))}
              </section>
            </Block>
          );
        })}
      </div>
    </Content>
  );
};

export default Notifications;
