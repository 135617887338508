import * as actionTypes from "./actionTypes";
import { resetSession } from "../../shared/helpers/session";
import { ErrorType, FileDataType } from "../../shared/types";
import FilesService from "../../services/api/files";

// FETCH
export const fetchFileStart = () => {
  return {
    type: actionTypes.FETCH_FILE_START,
  };
};

export const fetchFileSuccess = (file: FileDataType) => {
  return {
    type: actionTypes.FETCH_FILE_SUCCESS,
    file,
  };
};

export const fetchFileFail = (error: string) => {
  return {
    type: actionTypes.FETCH_FILE_FAIL,
    error,
  };
};

export const fetchFile = (id: string) => {
  return (dispatch: any) => {
    dispatch(fetchFileStart());

    FilesService.get(id).then(
      (response: { data: FileDataType }) => {
        dispatch(fetchFileSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(fetchFileFail(data.error));
      }
    );
  };
};

// CREATE
export const createFileStart = () => {
  return {
    type: actionTypes.CREATE_FILE_START,
  };
};

export const createFileSuccess = (files: FileDataType[]) => {
  return {
    type: actionTypes.CREATE_FILE_SUCCESS,
    files,
  };
};

export const createFileFail = (error: string) => {
  resetSession();

  return {
    type: actionTypes.CREATE_FILE_FAIL,
    error,
  };
};

export const createFile = (data: FileDataType) => {
  return (dispatch: any) => {
    dispatch(createFileStart());

    FilesService.create(data).then(
      (response: { data: FileDataType[] }) => {
        dispatch(createFileSuccess(response.data));
      },
      (data: ErrorType) => {
        dispatch(createFileFail(data.error));
      }
    );
  };
};

export const resetFile = () => {
  return {
    type: actionTypes.RESET_FILE,
  };
};
