import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/helpers/utilities";
import { PetDataType, UserDataType } from "../../shared/types";

type ActionType = {
  type: string;
  user: UserDataType;
  error: string;
  pets: PetDataType[];
};

const INITIAL_STATE = {
  user: null,
  isFetching: false,
  isUpdating: false,
  didInvalidate: false,
  error: null,
  pets: [],
};

const fetchUserStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    user: null,
    isFetching: true,
    didInvalidate: false,
    error: null,
  });
};

const fetchUserSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    user: action.user,
    isFetching: false,
    didInvalidate: false,
    error: null,
  });
};

const fetchUserFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true,
  });
};

const updateUserStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    isUpdating: true,
    didInvalidate: false,
    error: null,
  });
};

const updateUserSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    user: action.user,
    isUpdating: false,
    didInvalidate: false,
    error: null,
  });
};

const updateUserFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isUpdating: false,
    didInvalidate: true,
  });
};

const fetchUserPetsStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    isFetching: true,
    didInvalidate: false,
    error: null,
  });
};

const fetchUserPetsSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    pets: action.pets,
    isFetching: false,
    didInvalidate: false,
    error: null,
  });
};

const fetchUserPetsFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true,
  });
};

const resetUserPetsSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    pets: [],
  });
};

const resetUserSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    user: null,
    pets: [],
  });
};

const userReducer = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    // FETCH
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);

    // UPDATE
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);

    // FETCH PETS
    case actionTypes.FETCH_USER_PETS_START:
      return fetchUserPetsStart(state);
    case actionTypes.FETCH_USER_PETS_SUCCESS:
      return fetchUserPetsSuccess(state, action);
    case actionTypes.FETCH_USER_PETS_FAIL:
      return fetchUserPetsFail(state, action);

    case actionTypes.RESET_USER_PETS:
      return resetUserPetsSuccess(state, action);

    case actionTypes.RESET_USER:
      return resetUserSuccess(state, action);

    default:
      return state;
  }
};

export default userReducer;
