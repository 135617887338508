export const EMPTY = "/";
export const ACCOUNT = "/account";
export const PERSONAL_INFO = "/personal-info";
export const MY_PETS = "/my-pets";
export const MY_PETS_EDIT = "/my-pets/:id";
export const MY_PETS_NEW = "/my-pets/new";
export const ADDRESS = "/address";
export const NOTIFICATIONS = "/notifications";
export const LOGIN_AND_SECURITY = "/login-and-security";
export const SITTER_SIGNUP = "/sitter-signup";
export const SIGNIN = "/signin";
