import React, { useCallback, useEffect } from "react";
import classes from "./styles.module.scss";
import Content from "../../containers/Content";
import { personalInfo } from "../../shared/navigations/breadcrumbs";
import PhotoUploader from "../../components/PhotoUploader";
import Field from "../../components/Field";
import { personalInfoFields } from "../../shared/__mocks__/fields";
import { fakeUser } from "../../shared/__mocks__/user";
import { FormattedMessage } from "react-intl";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { FileDataType, UserDataType } from "../../shared/types";
import { getCurrentUserId, resetSession } from "../../shared/helpers/session";
import { useHistory } from "react-router-dom";
import * as routes from "../../shared/routes";
import RadioGroup from "../../components/RadioGroup";
import { GENDER } from "../../shared/enums";

type PersonalInfoProps = {
  onGetUser: (id: string) => UserDataType;
  onUpdateUser: (id: string, data: UserDataType) => UserDataType;
  user: Omit<UserDataType, "files">;
  waitingForGettingUser: boolean;
  waitingForUpdatingUser: boolean;
  userError: string;
};

const PersonalInfo = ({
  onGetUser,
  onUpdateUser,
  user,
  waitingForGettingUser,
  userError,
}: PersonalInfoProps) => {
  const history = useHistory();

  useEffect(() => {
    const userId = getCurrentUserId();

    if (!userId) {
      resetSession();
      history.push(routes.SIGNIN);
    } else {
      onGetUser(userId);
    }
  }, [history, onGetUser]);

  const handleSaveField = useCallback(
    (key, value) => {
      const userId = getCurrentUserId();

      if (!userId) {
        resetSession();
        history.push(routes.SIGNIN);
      } else {
        const data = {
          [key]: value,
        };

        onUpdateUser(userId, data);
      }
    },
    [onUpdateUser, history]
  );

  const handleUploadImage = useCallback(
    (files: FileDataType[]) => {
      const userId = getCurrentUserId();

      if (userId && files?.length) {
        const list: string[] = files
          .map((file) => file?.id || "")
          .filter((item) => item);
        onUpdateUser(userId, { files: list });
      }
    },
    [onUpdateUser]
  );

  return (
    <Content
      title={<FormattedMessage id="personalInfo.title" />}
      breadcrumbsList={personalInfo}
    >
      <div className={classes.PersonalInfo}>
        <div className={classes["PersonalInfo-photo"]}>
          <PhotoUploader
            onCallbackFile={handleUploadImage}
            tag="profile"
            title={<FormattedMessage id="label.profilePhoto" />}
            src={fakeUser.picture}
            alternativeText={`${fakeUser.firstname} ${fakeUser.lastname}`}
            contentText={<FormattedMessage id="uploadProfilePhoto" />}
          />
        </div>
        <div className={classes["PersonalInfo-data"]}>
          {user &&
            personalInfoFields.map((field, ix) => {
              return field.type === "radio" ? (
                <RadioGroup
                  title={<FormattedMessage id="gender" />}
                  key={ix}
                  items={[
                    {
                      label: <FormattedMessage id="male" />,
                      onChange: () => handleSaveField(field.key, GENDER.male),
                      id: "male",
                      checked:
                        user[field.key as keyof typeof user] === GENDER.male,
                    },
                    {
                      label: <FormattedMessage id="female" />,
                      onChange: () => handleSaveField(field.key, GENDER.female),
                      id: "female",
                      checked:
                        user[field.key as keyof typeof user] === GENDER.female,
                    },
                  ]}
                />
              ) : (
                <Field
                  disabled={waitingForGettingUser}
                  key={ix}
                  label={field.label}
                  value={
                    user && field.key
                      ? user[field.key as keyof typeof user] || ""
                      : field.value
                  }
                  onSave={(value: string | number) =>
                    handleSaveField(field.key, value)
                  }
                />
              );
            })}
        </div>
      </div>
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.userState.user,
    waitingForGettingUser: state.userState.isFetching,
    waitingForUpdatingUser: state.userState.isUpdating,
    userError: state.userState.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetUser: (id: string) => dispatch(actions.fetchUser(id)),
    onUpdateUser: (id: string, data: UserDataType) =>
      dispatch(actions.updateUser(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
