import classes from "./styles.module.scss";
import React, { ReactNode, useMemo } from "react";
import { randomId } from "../../shared/helpers/utilities";
import { RadioGroupItem } from "../../shared/types";
import { useClassNames } from "../../shared/hooks";

type RadioGroupProps = {
  title: string | ReactNode;
  description?: string | ReactNode;
  items: RadioGroupItem[];
};

const RadioGroup = ({
  title,
  description,
  items = [],
}: RadioGroupProps): JSX.Element | null => {
  const name = useMemo(() => `group-name${randomId()}`, []);

  const titleClassNames = useClassNames([
    classes["RadioGroup-title"],
    Boolean(description) ? classes["RadioGroup-title--withDescription"] : "",
  ]);

  if (!items.length) return null;

  return (
    <div className={classes.RadioGroup}>
      {title && <h3 className={titleClassNames}>{title}</h3>}
      {description && (
        <p className={classes["RadioGroup-description"]}>{description}</p>
      )}
      <ul className={classes["RadioGroup-list"]}>
        {items.map((item, ix) => {
          return (
            <li key={ix}>
              <input
                type="radio"
                id={item.id}
                name={name}
                checked={item.checked}
                onChange={item.onChange}
              />
              <label htmlFor={item.id}>{item.label}</label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RadioGroup;
