import axios, { AxiosRequestConfig } from "axios";
import { resetSession } from "../helpers/session";

const API_BASE = process.env.REACT_APP_API_BASE_URL;

//console.log("ENV", process.env);

/**
 * Create an Axios Clients with defaults
 */
// Default config options
let defaultOptions: AxiosRequestConfig = {
  baseURL: `${API_BASE}`,
};

// Create instance
let Client = axios.create();

interface IResponse {
  data: any;
  config: object;
  status: number;
  headers: object;
}

interface IError {
  message: any;
  config: any;
  response: IResponse;
  statusText: string;
  data: any;
}

/**
 * Request Wrapper with default success/error actions
 */
const request = (options: AxiosRequestConfig, debug?: boolean) => {
  const onSuccess = (response: IResponse) => {
    //console.log('Request Successful!', response);
    return response.data;
  };

  const onError = (error: IError) => {
    if (error && error.response.status === 500) {
      console.error("Request Failed:", error.config);
    }

    if (error && error.response) {
      // Request was made but server responded with something
      // other than 2xx
      if (error.response.status === 500) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      }

      if (error.response.status === 401) {
        resetSession();

        window.location.href = "/";
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error(
        "Error Message:",
        "Maybe a problem with Server or your network is off."
      );
    }

    return Promise.reject(
      error
        ? error.data || error.statusText
        : "Maybe a problem with Server or your network is off."
    );
  };

  if (debug) {
    defaultOptions = {
      ...defaultOptions,
    };
  }

  const config = {
    ...defaultOptions,
    ...options,
  };

  return Client(config).then(onSuccess).catch(onError);
};

export default request;
