import { ServiceType } from "../types";
import dogWalkingImage from "../../assets/images/dog-walking.svg";
import houseImage from "../../assets/images/house.svg";
import petDayCareImage from "../../assets/images/pet-day-care.svg";
import dropInVisitsImage from "../../assets/images/drop-in-visits.svg";
import petBoardingImage from "../../assets/images/pet-boarding.svg";

export const services: ServiceType[] = [
  {
    name: "houseSitting",
    labelId: "houseSitting",
    imageUrl: houseImage,
  },
  {
    name: "dogWalking",
    labelId: "dogWalking",
    imageUrl: dogWalkingImage,
    additionalRates: [
      {
        name: "dogWalking.additionalPetRate",
        labelId: "additionalPetRate",
      },
      {
        name: "dogWalking.puppyRate",
        labelId: "puppyRate",
      },
    ],
  },
  {
    name: "petDayCare",
    descriptionId: "petDayCare.description",
    labelId: "petDayCare",
    imageUrl: petDayCareImage,
    additionalRates: [
      {
        name: "petDayCare.additionalPetRate",
        labelId: "additionalPetRate",
      },
      {
        name: "petDayCare.puppyRate",
        labelId: "puppyRate",
      },
    ],
  },
  {
    name: "dropInVisits",
    labelId: "dropInVisits",
    imageUrl: dropInVisitsImage,
    additionalRates: [
      {
        name: "dropInVisits.additionalPetRate",
        labelId: "additionalPetRate",
      },
      {
        name: "dropInVisits.puppyRate",
        labelId: "puppyRate",
      },
    ],
  },
  {
    name: "petBoarding",
    labelId: "petBoarding",
    imageUrl: petBoardingImage,
    additionalRates: [
      {
        name: "petBoarding.additionalPetRate",
        labelId: "additionalPetRate",
      },
      {
        name: "petBoarding.puppyRate",
        labelId: "puppyRate",
      },
      {
        name: "petBoarding.holidayRate",
        labelId: "holidayRate",
      },
    ],
  },
];
