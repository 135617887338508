import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import GreenButton from "../GreenButton";
import { FormattedMessage } from "react-intl";

export type WizardProgressProps = {
  value: number;
  text: string | ReactNode;
  onClick(): void;
};

const WizardProgress = ({
  value = 0,
  text,
  onClick,
}: WizardProgressProps): JSX.Element => {
  return (
    <section className={classes.WizardProgress}>
      <div className={classes["WizardProgress-bar"]}>
        <div style={{ width: `${value}%` }} />
      </div>
      <span className={classes["WizardProgress-text"]}>{text}</span>
      <div className={classes["WizardProgress-submit"]}>
        <GreenButton
          disabled={value < 100}
          onClick={onClick}
          text={<FormattedMessage id="progress.wizard.submit" />}
        />
      </div>
    </section>
  );
};

export default WizardProgress;
