import classes from "./styles.module.scss";
import React, { ReactNode, RefObject, useCallback, useState } from "react";
import Modal from "../../containers/Modal";
import FileUploader from "../FileUploader";
import { FormattedMessage } from "react-intl";
import uploadIcon from "../../assets/icons/upload.svg";
import Input from "../Input";

type UploadModalProps = {
  title: string | ReactNode;
  fileName: string;
  onClose(): void;
  reference: RefObject<HTMLDivElement>;
  label: ReactNode | string;
};

const UploadModal = ({
  title,
  onClose,
  fileName = "",
  label,
}: UploadModalProps): JSX.Element | null => {
  const [name, setName] = useState(fileName);

  const onChangeName = useCallback((value: string) => {
    setName(value);
  }, []);

  const onHandleConfirm = useCallback(() => {
    console.log("Save!");
  }, []);

  return (
    <div className={classes.UploadModal}>
      <Modal title={title} onClose={onClose} onConfirm={onHandleConfirm}>
        <FileUploader
          onClick={(): void => console.log("Upload file from modal")}
          uploaderIconUrl={uploadIcon}
          fullWidth={true}
          contentText={<FormattedMessage id="certificate.upload" />}
        />
        <div className={classes["ModalUpload-input"]}>
          <Input
            label={label}
            value={name}
            onChange={(value: string): void => onChangeName(value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UploadModal;
