import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

type OutlineButtonProps = {
  text?: string | ReactNode;
  onClick(): void;
  disabled?: boolean;
};

const OutlineButton = ({
  text,
  onClick,
  disabled = false,
}: OutlineButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={classes.OutlineButton}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default OutlineButton;
