import request from "../../shared/lib/request";
import constants from "../../shared/constants";
import { FileDataType } from "../../shared/types";

const get = (id: string) => {
  const authToken = localStorage.getItem(
    constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN
  );

  let headers: any = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authToken}`,
  };
  const url = `/api/v1/file/${id}`;

  return request({
    url,
    method: "GET",
    headers,
  });
};

const create = (data: FileDataType) => {
  const authToken = localStorage.getItem(
    constants.LOCAL_STORAGE.KEYS.AUTH_TOKEN
  );

  let headers: any = {
    "Content-Type": `multipart/form-data boundary=${Math.random()
      .toString()
      .substring(2)}`,
    Authorization: `Bearer ${authToken}`,
  };

  return request({
    url: "/api/v1/file",
    method: "POST",
    headers,
    data: data.content,
  });
};

const FilesService = {
  get,
  create,
};

export default FilesService;
