import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/helpers/utilities";
import { FileDataType } from "../../shared/types";

type ActionType = {
  type: string;
  files: FileDataType[];
  error: string;
};

const INITIAL_STATE = {
  file: null,
  isCreating: false,
  isFetching: false,
  didInvalidate: false,
  error: null,
};

const fetchFileStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    files: [],
    isFetching: true,
    didInvalidate: false,
    error: null,
    actionSuccess: false,
  });
};

const fetchFileSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    files: action.files,
    isFetching: false,
  });
};

const fetchFileFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true,
  });
};

const createFileStart = (state = INITIAL_STATE) => {
  return updateObject(state, {
    file: null,
    isCreating: true,
    didInvalidate: false,
    error: null,
    actionSuccess: false,
  });
};

const createFileSuccess = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    files: action.files,
    isCreating: false,
    actionSuccess: true,
  });
};

const createFileFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isCreating: false,
    didInvalidate: true,
  });
};

const resetFile = (state = INITIAL_STATE) => {
  return updateObject(state, {
    file: null,
    isCreating: false,
    isFetching: false,
    didInvalidate: false,
    actionSuccess: false,
    error: null,
  });
};

const fileReducer = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    // FETCH
    case actionTypes.FETCH_FILE_START:
      return fetchFileStart(state);
    case actionTypes.FETCH_FILE_SUCCESS:
      return fetchFileSuccess(state, action);
    case actionTypes.FETCH_FILE_FAIL:
      return fetchFileFail(state, action);

    // CREATE
    case actionTypes.CREATE_FILE_START:
      return createFileStart(state);
    case actionTypes.CREATE_FILE_SUCCESS:
      return createFileSuccess(state, action);
    case actionTypes.CREATE_FILE_FAIL:
      return createFileFail(state, action);

    // RESET
    case actionTypes.RESET_FILE:
      return resetFile(state);

    default:
      return state;
  }
};

export default fileReducer;
