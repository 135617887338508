import * as actionTypes from "./actionTypes";
import SessionsService from "../../services/api/sessions";
import { resetSession, setSession } from "../../shared/helpers/session";
import { SessionType, SigninDataType } from "../../shared/types";

// SIGNIN
export const signinStart = () => {
  return {
    type: actionTypes.SIGNIN_START,
  };
};

export const signinSuccess = (session: SessionType) => {
  return {
    type: actionTypes.SIGNIN_SUCCESS,
    session,
  };
};

export const signinFail = (error: string) => {
  return {
    type: actionTypes.SIGNIN_FAIL,
    error,
  };
};

export const signin = (data: SigninDataType) => {
  return (dispatch: any) => {
    dispatch(signinStart());

    SessionsService.signin(data).then(
      (session: { data: SessionType }) => {
        setSession(session.data);

        dispatch(signinSuccess(session.data));
      },
      () => {
        dispatch(signinFail("User not found."));
      }
    );
  };
};

// SIGNOUT
export const resetUserSession = () => {
  resetSession();

  return {
    type: actionTypes.RESET_SESSION,
  };
};
