const constantsValues = {
  LOCAL_STORAGE: {
    KEYS: {
      USER_ID: "floofy_user_id",
      USER_ROLE: "floofy_user_role",
      AUTH_TOKEN: "floofy_auth_token",
      EXPIRATION_DATE: "floofy_expiration_date",
      CURRENT_RECAPTCHA: "floofy_current_recaptcha",
      LOCALE: "floofy_locale",
    },
  },
};

export default constantsValues;
