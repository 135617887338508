export const isValidEmail = (email: string): boolean => {
  if (!email || !email?.trim().length) {
    return false;
  }

  const regExp =
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

  const matcher = new RegExp(regExp);
  const keywordTest = email.trim();

  return matcher.test(keywordTest);
};
