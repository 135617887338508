import React from "react";
import Header from "./containers/Header";
import Main from "./containers/Main";
import Footer from "./containers/Footer";
import RouterWrapper from "./containers/RouterWrapper";
import { BrowserRouter } from "react-router-dom";

import itMessages from "./translations/it.json";
import enMessages from "./translations/en.json";
import esMessages from "./translations/es.json";

import { IntlProvider } from "react-intl";
import { getBrowserNavigatorLanguage } from "./shared/helpers/language";

const DEFAULT_LANGUAGE = "en";

const messages: { [key: string]: Record<string, string> } = {
  it: itMessages,
  en: enMessages,
  es: esMessages,
};

let language = getBrowserNavigatorLanguage();

if (!messages[language]) {
  language = Object.keys(messages)[0];
}

function App() {
  return (
    <IntlProvider
      defaultLocale={DEFAULT_LANGUAGE}
      locale={language}
      messages={messages[language] || messages[DEFAULT_LANGUAGE]}
    >
      <BrowserRouter>
        <Header />
        <Main>
          <RouterWrapper />
        </Main>
        <Footer />
      </BrowserRouter>
    </IntlProvider>
  );
}

export default App;
