import request from "../../shared/lib/request";
import { SigninDataType } from "../../shared/types";

const signin = (data: SigninDataType) => {
  let headers: any = {
    "Content-Type": "application/json",
  };

  return request({
    url: "/api/v1/session",
    method: "POST",
    headers,
    data,
  });
};

const SessionsService = {
  signin,
};

export default SessionsService;
