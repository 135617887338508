import React, { useCallback, useEffect } from "react";
import classes from "./styles.module.scss";
import Content from "../../containers/Content";
import { myPets } from "../../shared/navigations/breadcrumbs";
import { FormattedMessage } from "react-intl";
import PetTile from "../../components/PetTile";
import EmptyBox from "../../components/EmptyBox";
import { useHistory } from "react-router-dom";
import * as routes from "../../shared/routes";
import { PetDataType } from "../../shared/types";
import * as actions from "../../store/actions";
import { connect } from "react-redux";
import { getCurrentUserId, resetSession } from "../../shared/helpers/session";
import Loader from "../../components/Loader";

type MyPetsProps = {
  onGetPets: (id: string) => PetDataType[];
  pets: PetDataType[];
  waitingForFetching: boolean;
  error: string;
};

const MyPets = ({
  onGetPets,
  pets,
  waitingForFetching,
  error,
}: MyPetsProps) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userId = getCurrentUserId();

    if (userId) {
      if (!pets?.length) {
        onGetPets(userId);
      }
    } else {
      resetSession();
      history.push(routes.SIGNIN);
    }
  }, [onGetPets, history, pets]);

  const onHandleNewClick = useCallback(() => {
    history.push(routes.MY_PETS_NEW);
  }, [history]);

  return (
    <Content
      title={<FormattedMessage id="myPets.title" />}
      breadcrumbsList={myPets}
    >
      {waitingForFetching ? (
        <Loader />
      ) : (
        <div className={classes.MyPets}>
          {pets.map((pet) => (
            <PetTile
              key={pet.id}
              imageSrc={undefined}
              name={pet?.name || ""}
              description={pet.type}
              //extra={`${pet.years} years, ${pet.months} months, ${pet.weight} Kg`}
              extra={`${pet.weight} Kg`}
              id={pet.id}
            />
          ))}
          <EmptyBox
            text={<FormattedMessage id="addPet" />}
            minHeight="240px"
            onClick={onHandleNewClick}
          />
        </div>
      )}
    </Content>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pets: state.userState.pets,
    waitingForFetching: state.userState.isFetching,
    error: state.userState.error,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetPets: (id: string) => dispatch(actions.fetchUserPets(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPets);
