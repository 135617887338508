import React, { ChangeEvent, useCallback } from "react";
import classes from "./styles.module.scss";
import { SelectOption } from "../../shared/types";
import expandIcon from "../../assets/icons/expand.svg";

export type SelectProps = {
  options: SelectOption[];
  onChange: (value: string) => void;
  value: string | number;
  maxWidth?: number;
};

export default function Select({
  onChange,
  value,
  options = [],
  maxWidth,
}: SelectProps): JSX.Element {
  const onChangeEvent = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      onChange(event.currentTarget.value);
    },
    [onChange]
  );

  return (
    <div
      className={classes.Select}
      style={{
        maxWidth: maxWidth ? maxWidth : "100%",
      }}
    >
      <select onChange={onChangeEvent} value={value}>
        {options.map((option: SelectOption) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className={classes["Select-focus"]} />
      <img src={expandIcon} alt="expand" />
    </div>
  );
}
