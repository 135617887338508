import React from "react";
import classes from "./styles.module.scss";

export type BlockProps = {
  src: string;
};

const Map = ({ src }: BlockProps): JSX.Element | null => {
  if (!src) return null;
  return (
    <iframe
      title="map"
      className={classes.Map}
      frameBorder="0"
      scrolling="no"
      src="https://www.openstreetmap.org/export/embed.html?bbox=-0.20416975021362307%2C51.537890766118224%2C-0.20149290561676028%2C51.53917535886987&amp;layer=mapnik&amp;marker=51.53853306702627%2C-0.20283132791519165"
    />
  );
};

export default Map;
