import classes from "./styles.module.scss";
import React, { ReactNode } from "react";
import { CheckboxGroupItem } from "../../shared/types";
import Checkbox from "../Checkbox";

type CheckboxGroupProps = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  items: CheckboxGroupItem[];
};

const CheckboxGroup = ({
  title,
  description,
  items = [],
}: CheckboxGroupProps): JSX.Element | null => {
  if (!items.length) return null;

  return (
    <div className={classes.CheckboxGroup}>
      {title && <h3 className={classes["CheckboxGroup-title"]}>{title}</h3>}
      {description && (
        <p className={classes["CheckboxGroup-description"]}>{description}</p>
      )}
      <ul className={classes["CheckboxGroup-list"]}>
        {items.map((item, ix) => {
          return (
            <li key={ix}>
              <Checkbox
                checked={item.checked}
                id={item.id}
                label={item.label}
                onChange={item.onChange}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CheckboxGroup;
