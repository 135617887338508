import React from "react";
import classes from "./styles.module.scss";
import LinksList from "../../components/LinksList";
import { aboutLinks } from "../../shared/__mocks__/links";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";

const Footer = (): JSX.Element => {
  return (
    <footer className={classes.Footer}>
      <div className={classes["Footer-content"]}>
        <div className={classes["Footer-logo"]}>
          <LinksList
            title={<FormattedMessage id="general.about" />}
            items={aboutLinks}
          />
        </div>
      </div>
      <div className={classes["Footer-credits"]}>
        <span>
          &copy;
          <FormattedMessage id="credits" values={{ year: dayjs().year() }} />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
