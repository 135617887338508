import React from "react";
import classes from "./styles.module.scss";
import Title from "../../components/Title";
import LinkBox from "../../components/LinkBox";
import { LINK_BOXES_ITEMS } from "../../shared/navigations/account";
import GreenButton from "../../components/GreenButton";
import { BUTTON_TYPE } from "../../shared/enums";
import { FormattedMessage } from "react-intl";

const Account = () => {
  return (
    <div className={classes.Account}>
      <header className={classes["Account-header"]}>
        <Title>
          <FormattedMessage id="general.account" />
        </Title>
      </header>
      <section className={classes["Account-content"]}>
        {LINK_BOXES_ITEMS.map((item) => {
          return (
            <LinkBox
              id={item.id}
              key={item.id}
              icon={item.icon}
              title={item.title}
              description={item.description}
              redirectTo={item.redirectTo}
            />
          );
        })}
      </section>
      <footer className={classes["Account-footer"]}>
        <h4>
          <FormattedMessage id="deactivate.title" />
        </h4>
        <GreenButton
          type={BUTTON_TYPE.secondary}
          text={<FormattedMessage id="deactivate.cta" />}
          onClick={(): void => alert("Available soon!")}
        />
      </footer>
    </div>
  );
};

export default Account;
