import classes from "./styles.module.scss";
import React, { useMemo } from "react";
import { randomId } from "../../shared/helpers/utilities";
import { CheckboxGroupItem } from "../../shared/types";
import { FLOW } from "../../shared/enums";
import { useClassNames } from "../../shared/hooks";

const Checkbox = ({
  checked = false,
  id,
  label,
  onChange,
  flow = FLOW.row,
}: CheckboxGroupItem): JSX.Element | null => {
  const name = useMemo(() => `group-name${randomId()}`, []);

  const classNames = useClassNames([
    classes.Checkbox,
    flow === FLOW.column ? classes["Checkbox--column"] : "",
  ]);

  return (
    <div className={classNames}>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
