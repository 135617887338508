import React, { ReactNode, useCallback, useMemo, useRef } from "react";
import classes from "./styles.module.scss";
import closeIcon from "../../assets/icons/close.svg";
import OutlineButton from "../../components/OutlineButton";
import { FormattedMessage } from "react-intl";
import GreenButton from "../../components/GreenButton";
import { useOnClickOutside } from "../../shared/hooks";
import Backdrop from "../Backdrop";

export type ModalProps = {
  children: ReactNode;
  title: string | ReactNode;
  onClose?(): void;
  hideCancelButton?: boolean;
  onConfirm?(): void;
  confirmFullWidth?: boolean;
  confirmTextId?: string;
  disableConfirm?: boolean;
};

export default function Modal({
  children,
  title,
  onClose,
  onConfirm,
  confirmFullWidth = false,
  confirmTextId = "save",
  hideCancelButton = false,
  disableConfirm = false,
}: ModalProps): JSX.Element {
  const modalRef = useRef<HTMLDivElement>(null);
  const showFooter = useMemo(
    () => Boolean(onClose) || Boolean(onConfirm),
    [onClose, onConfirm]
  );

  const handleClickOutsideOverlay = useCallback((): void => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useOnClickOutside(modalRef, handleClickOutsideOverlay);

  return (
    <Backdrop>
      <div ref={modalRef} className={classes.Modal}>
        <header className={classes["Modal-header"]}>
          <h2 className={classes["Modal-title"]}>{title}</h2>
          {onClose && (
            <button className={classes["Modal-close"]} onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </button>
          )}
        </header>
        <div className={classes["Modal-content"]}>{children}</div>
        {showFooter && (
          <footer className={classes["Modal-footer"]}>
            {onClose && !hideCancelButton && (
              <OutlineButton
                text={<FormattedMessage id="cancel" />}
                onClick={onClose}
              />
            )}
            {onConfirm && (
              <GreenButton
                disabled={disableConfirm}
                text={<FormattedMessage id={confirmTextId} />}
                fullWidth={confirmFullWidth}
                onClick={onConfirm}
              />
            )}
          </footer>
        )}
      </div>
    </Backdrop>
  );
}
