import { PetType } from "../types";

export const fakePets: PetType[] = [
  {
    id: 1,
    name: "Lucky",
    race: "Pit Bull",
    years: 12,
    months: 3,
    weight: 10,
    image:
      "https://www.repstatic.it/content/localirep/img/rep-napoli/2019/08/26/090925479-b3311c67-ddb3-4aeb-b632-86a5f5c6f773.jpg",
  },
  {
    id: 2,
    name: "Micia",
    race: "Persia",
    years: 4,
    months: 4,
    weight: 4,
    image:
      "https://img.webmd.com/dtmcms/live/webmd/consumer_assets/site_images/article_thumbnails/other/cat_relaxing_on_patio_other/1800x1200_cat_relaxing_on_patio_other.jpg",
  },
  {
    id: 3,
    name: "Pippo",
    race: "Parrot",
    years: 2,
    months: 0,
    weight: 1,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Parrot.jpg/2560px-Parrot.jpg",
  },
];
