import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

export type ServiceNameProps = {
  label: ReactNode | string;
  imageSrc: string;
};

const ServiceName = ({ label, imageSrc }: ServiceNameProps) => {
  return (
    <div className={classes.ServiceName}>
      <img src={imageSrc} alt="house sitting" />
      <span className={classes["ServiceName-label"]}>{label}</span>
    </div>
  );
};

export default ServiceName;
