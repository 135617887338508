import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";
import { services } from "../../shared/__mocks__/services";
import Service from "../../components/Service";
import { ServiceDataType } from "../../shared/types";

type ServicesAndRatesProps = {
  onNext(): void;
  onPrev(): void;
};

const ServicesAndRates = ({ onNext, onPrev }: ServicesAndRatesProps) => {
  const [data, setData] = useState<ServiceDataType[]>([]);

  const onHandleChangeData = useCallback(
    (obj: ServiceDataType) => {
      let newData = [...data];

      if (data.length > 0) {
        const found = data.find((item) => item.name === obj.name);

        if (found) {
          newData = data.map((item) => {
            if (item.name === obj.name) {
              return obj;
            }

            return item;
          });
        } else {
          newData.push(obj);
        }
      } else {
        newData.push(obj);
      }

      setData(newData);
    },
    [data]
  );

  useEffect(() => {
    console.log("Data to save", data);
  }, [data]);

  return (
    <InnerContent
      overallText={<FormattedMessage id="servicesAndRates.overallText" />}
      title={<FormattedMessage id="servicesAndRates.title" />}
      description={<FormattedMessage id="servicesAndRates.description" />}
      onHandleNext={onNext}
      onHandleBack={onPrev}
    >
      <div className={classes.ServicesAndRates}>
        <div className={classes["ServicesAndRates-content"]}>
          <section className={classes["ServicesAndRates-wrapper"]}>
            <ul>
              {services.map((service, index) => {
                return (
                  <li key={index}>
                    <Service
                      name={service.name}
                      labelId={service.labelId}
                      descriptionId={service.descriptionId}
                      imageUrl={service.imageUrl}
                      onCallbackData={onHandleChangeData}
                      additionalRates={service.additionalRates}
                    />
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      </div>
    </InnerContent>
  );
};

export default ServicesAndRates;
