import React from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";

type PayoutMethodProps = {
  onNext(): void;
  onPrev(): void;
};

const PayoutMethod = ({ onNext, onPrev }: PayoutMethodProps) => {
  return (
    <InnerContent
      overallText={<FormattedMessage id="payoutMethod.overallText" />}
      title={
        <FormattedMessage id="sitterSignup.wizardNavigation.payoutMethod" />
      }
      description={<FormattedMessage id="payoutMethod.description" />}
      onHandleNext={onNext}
      onHandleBack={onPrev}
    >
      <div className={classes.PayoutMethod}>
        <div className={classes["PayoutMethod-content"]}>
          <div className={classes["PayoutMethod-list"]}>STRIPE</div>
        </div>
      </div>
    </InnerContent>
  );
};

export default PayoutMethod;
