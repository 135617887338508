import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { FormattedMessage } from "react-intl";
import InnerContent from "../InnerContent";
import Select from "../../components/Select";
import {
  minimumNotices,
  cancellationPolicies,
} from "../../shared/__mocks__/options";
import Group from "../../components/Group";
import CheckboxGroup from "../../components/CheckboxGroup";
import { FEATURE } from "../../shared/enums";

type BookingPolicyProps = {
  onNext(): void;
  onPrev(): void;
};

const PET_TYPE_OPTIONS: string[] = [
  "allDomesticPets",
  "dogs",
  "cats",
  "fish",
  "birds",
  "reptiles",
  "otherPets",
];

const PET_SIZE_OPTIONS: string[] = [
  "size.small",
  "size.medium",
  "size.large",
  "size.xLarge",
];

const PET_AGE_OPTIONS: string[] = ["puppies", "adults", "seniors"];

const REQUIREMENTS: string[] = ["fixed", "vaccinated"];

const PET_POTTY_BREAK_OPTIONS: string[] = ["2h", "4h", "6h", "8h"];

let FIELDS: {
  [key: string]: {
    value: string;
    selected?: string[];
  };
} = {
  minimumNotice: {
    value: "",
  },
  cancellationPolicy: {
    value: "",
  },
  petType: {
    value: "",
    selected: [],
  },
  petSize: {
    value: "",
    selected: [],
  },
  petPottyBreakTime: {
    value: "",
    selected: [],
  },
  petAge: {
    value: "",
    selected: [],
  },
  requirements: {
    value: "",
    selected: [],
  },
};

const BookingPolicy = ({ onNext, onPrev }: BookingPolicyProps) => {
  const [currentFields, setCurrentFields] = useState(FIELDS);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const onHandleChangeValue = useCallback(
    (name: string, value: string) => {
      const newFields: {
        [key: string]: {
          value: string;
          selected?: string[];
        };
      } = {
        ...currentFields,
        [name]: {
          ...currentFields[name],
          value,
        },
      };

      setCurrentFields(newFields);

      return true;
    },
    [currentFields]
  );

  const resetSelectedPetSizes = useCallback(() => {
    let tempFields = { ...currentFields };

    if (
      !tempFields.petType.selected ||
      !tempFields.petType.selected.length ||
      (tempFields.petType.selected &&
        tempFields.petType.selected.length &&
        !tempFields.petType.selected.includes(PET_TYPE_OPTIONS[0]) &&
        !tempFields.petType.selected.includes(PET_TYPE_OPTIONS[1]))
    ) {
      tempFields.petSize.selected = [];
      tempFields.petPottyBreakTime.selected = [];
      tempFields.petAge.selected = [];
      tempFields.requirements.selected = [];

      setCurrentFields(tempFields);
    }
  }, [currentFields]);

  const onCheckOption = useCallback(
    (exp: string, type: FEATURE) => {
      let tempFields = { ...currentFields };

      if (
        exp === PET_TYPE_OPTIONS[0] &&
        !tempFields.petType.selected?.includes(exp)
      ) {
        tempFields.petType.selected = PET_TYPE_OPTIONS;
      } else {
        if (type === FEATURE.pet) {
          if (tempFields.petType.selected) {
            const found = tempFields.petType.selected.find(
              (item) => item === exp
            );

            if (found) {
              tempFields.petType.selected = tempFields.petType.selected.filter(
                (item) => item !== exp
              );
            } else {
              tempFields.petType.selected.push(exp);
            }
          }

          if (
            PET_TYPE_OPTIONS.length !== tempFields.petType.selected?.length &&
            tempFields.petType.selected
          ) {
            tempFields.petType.selected = tempFields.petType.selected.filter(
              (item) => item !== PET_TYPE_OPTIONS[0]
            );
          }

          resetSelectedPetSizes();
        }
      }

      if (type === FEATURE.size) {
        if (tempFields.petSize.selected) {
          const found = tempFields.petSize.selected.find(
            (item) => item === exp
          );

          if (found) {
            tempFields.petSize.selected = tempFields.petSize.selected.filter(
              (item) => item !== exp
            );
          } else {
            tempFields.petSize.selected.push(exp);
          }
        }
      }

      if (type === FEATURE.time) {
        if (tempFields.petPottyBreakTime.selected) {
          const found = tempFields.petPottyBreakTime.selected.find(
            (item) => item === exp
          );

          if (found) {
            tempFields.petPottyBreakTime.selected = tempFields.petPottyBreakTime.selected.filter(
              (item) => item !== exp
            );
          } else {
            tempFields.petPottyBreakTime.selected.push(exp);
          }
        }
      }

      if (type === FEATURE.age) {
        if (tempFields.petAge.selected) {
          const found = tempFields.petAge.selected.find((item) => item === exp);

          if (found) {
            tempFields.petAge.selected = tempFields.petAge.selected.filter(
              (item) => item !== exp
            );
          } else {
            tempFields.petAge.selected.push(exp);
          }
        }
      }

      if (type === FEATURE.requirement) {
        if (tempFields.requirements.selected) {
          const found = tempFields.requirements.selected.find(
            (item) => item === exp
          );

          if (found) {
            tempFields.requirements.selected = tempFields.requirements.selected.filter(
              (item) => item !== exp
            );
          } else {
            tempFields.requirements.selected.push(exp);
          }
        }
      }

      setCurrentFields(tempFields);
    },
    [currentFields, resetSelectedPetSizes]
  );

  useEffect(() => {
    setShowAdvanced(
      Boolean(
        currentFields.petType.selected &&
          (currentFields.petType.selected.includes(PET_TYPE_OPTIONS[0]) ||
            currentFields.petType.selected.includes(PET_TYPE_OPTIONS[1]))
      )
    );
  }, [currentFields]);

  return (
    <InnerContent
      overallText={<FormattedMessage id="bookingPolicy.overallText" />}
      title={<FormattedMessage id="bookingPolicy.title" />}
      description={<FormattedMessage id="bookingPolicy.description" />}
      onHandleBack={onPrev}
      onHandleNext={onNext}
    >
      <div className={classes.BookingPolicy}>
        <div className={classes["BookingPolicy-content"]}>
          <section className={classes["BookingPolicy-wrapper"]}>
            <Group>
              <Select
                options={minimumNotices}
                onChange={(value: string): boolean =>
                  onHandleChangeValue("minimumNotice", value)
                }
                value={currentFields.minimumNotice.value}
              />
            </Group>
            <Group>
              <Select
                options={cancellationPolicies}
                onChange={(value: string): boolean =>
                  onHandleChangeValue("cancellationPolicy", value)
                }
                value={currentFields.cancellationPolicy.value}
              />
            </Group>
            <CheckboxGroup
              title={<FormattedMessage id="petType.title" />}
              description={<FormattedMessage id="petType.description" />}
              items={PET_TYPE_OPTIONS.map((petType) => {
                return {
                  label: <FormattedMessage id={petType} />,
                  onChange: () => onCheckOption(petType, FEATURE.pet),
                  id: petType,
                  checked: Boolean(
                    currentFields.petType.selected &&
                      currentFields.petType.selected.includes(petType)
                  ),
                };
              })}
            />
            {showAdvanced && (
              <>
                <CheckboxGroup
                  title={<FormattedMessage id="bookingPolicy.size" />}
                  items={PET_SIZE_OPTIONS.map((petSize) => {
                    return {
                      label: <FormattedMessage id={petSize} />,
                      onChange: () => onCheckOption(petSize, FEATURE.size),
                      id: petSize,
                      checked: Boolean(
                        currentFields.petSize.selected &&
                          currentFields.petSize.selected.includes(petSize)
                      ),
                    };
                  })}
                />
                <CheckboxGroup
                  title={<FormattedMessage id="bookingPolicy.pottyBreakTime" />}
                  items={PET_POTTY_BREAK_OPTIONS.map((petPottyBreakTime) => {
                    return {
                      label: <FormattedMessage id={petPottyBreakTime} />,
                      onChange: () =>
                        onCheckOption(petPottyBreakTime, FEATURE.time),
                      id: petPottyBreakTime,
                      checked: Boolean(
                        currentFields.petPottyBreakTime.selected &&
                          currentFields.petPottyBreakTime.selected.includes(
                            petPottyBreakTime
                          )
                      ),
                    };
                  })}
                />
                <CheckboxGroup
                  title={<FormattedMessage id="bookingPolicy.age" />}
                  items={PET_AGE_OPTIONS.map((petAge) => {
                    return {
                      label: <FormattedMessage id={petAge} />,
                      onChange: () => onCheckOption(petAge, FEATURE.age),
                      id: petAge,
                      checked: Boolean(
                        currentFields.petAge.selected &&
                          currentFields.petAge.selected.includes(petAge)
                      ),
                    };
                  })}
                />
                <CheckboxGroup
                  title={<FormattedMessage id="bookingPolicy.requirements" />}
                  items={REQUIREMENTS.map((requirement) => {
                    return {
                      label: <FormattedMessage id={requirement} />,
                      onChange: () =>
                        onCheckOption(requirement, FEATURE.requirement),
                      id: requirement,
                      checked: Boolean(
                        currentFields.requirements.selected &&
                          currentFields.requirements.selected.includes(
                            requirement
                          )
                      ),
                    };
                  })}
                />
              </>
            )}
          </section>
        </div>
      </div>
    </InnerContent>
  );
};

export default BookingPolicy;
